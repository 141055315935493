import { FC } from "react";
import styles from "../styles.module.css";

const CustomText: FC<{ text: string; color?: string; fontSize?: string }> = ({
  color,
  fontSize,
  text,
}) => {
  return (
    <div className={styles.CustomText} style={{ color, fontSize }}>
      <span>{text}</span>
    </div>
  );
};

export default CustomText;
