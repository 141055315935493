import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import noTranslations from './locales/no/translation_no.json';
import enTranslations from './locales/en/translation_en.json';

i18n
    .use(LanguageDetector) // Automatically detect the user's language
    .use(initReactI18next)  // Bind react-i18next to the instance
    .init({
        resources: {
            no: {
                translation: noTranslations,
            },
            en: {
                translation: enTranslations,
            },
        },
        fallbackLng: 'no', // Use English if the user's language is not available
        interpolation: {
            escapeValue: false, // React already does escaping
        },
    });

export default i18n;