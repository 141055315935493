import React, { FC, useEffect, useState } from "react";
import { PDFViewer, PDFViewerTool } from "@progress/kendo-react-pdf-viewer";
import { useParams } from "react-router";
import { getPdfApi } from "api/pdf";

const tools: PDFViewerTool[] = [
    "pager",
    "spacer",
    "zoomInOut",
    "zoom",
    "selection",
    "spacer",
    "search",
    //"open",
    "download",
    "print",
];

export const PdfViewer: FC<{ }> = ({ }) => {

    const { id, type } = useParams();
    const [pdfData, setPdfData] = useState<string>();


	useEffect(() => {
        console.log('id', id, type);

        if (!id || !type) {
			return;
		}

        getPdfApi(id, type)
            .then((response) => {
                console.log('pdf response', response);
                setPdfData(response);
            })
            .catch((err) => {
                console.log(err);
            });


    }, []);




    return <PDFViewer data={pdfData} style={{ height: "100%" }} tools={tools} />;
}

export default PdfViewer;