import { Input } from "@progress/kendo-react-inputs";
import { Label } from "@progress/kendo-react-labels";
import { SalesNoteHead } from "interface/salesNote";
import { FC, useState } from "react";

interface SingerForm {
	vesselSigner: string;
	apprenticeSigner: string;
	buyerSigner: string;
	quotaTypeCode: string;
	showError: boolean;
}

const Signature: FC<{
	signers: SingerForm;
	setSigners: (form: SingerForm) => void;
}> = ({ signers, setSigners }) => {
	return (
		<div>
			<div style={{ display: "flex", width: "100%", gap: "50px" }}>
				<div className={"k-form-field-wrap"} style={{ width: "100%" }}>
					<Label>Signant for fartøy*</Label>
					<Input
						placeholder="DDMMÅÅEEEFFF"
						width={"100%"}
						value={signers?.vesselSigner}
						valid={signers.showError && !signers.vesselSigner}
						onChange={(e) => setSigners({ ...signers, vesselSigner: e.value })}
					/>
				</div>
				{signers.quotaTypeCode === "13" && (
					<div className={"k-form-field-wrap"} style={{ width: "100%" }}>
						<Label>Signant for apprentice*</Label>
						<Input
							placeholder="DDMMÅÅEEEFFF"
							width={"100%"}
							value={signers?.apprenticeSigner}
							onChange={(e) =>
								setSigners({ ...signers, apprenticeSigner: e.value })
							}
						/>
					</div>
				)}

				<div className={"k-form-field-wrap"} style={{ width: "100%" }}>
					<Label>Signant for kjøper*</Label>
					<Input
						placeholder="DDMMÅÅEEEFFF"
						width={"100%"}
						value={signers?.buyerSigner}
						valid={signers.showError && !signers.buyerSigner}
						onChange={(e) => setSigners({ ...signers, buyerSigner: e.value })}
					/>
				</div>
			</div>
			<div style={{ marginTop: "30px" }}>
				<span style={{ display: "block", marginBottom: "20px" }}>
					Brukernavn fra CatchSign-appen i formatet DDMMÅÅEEEFFF
				</span>
				<div>
					<span>DDMMÅÅ = Fødselsdato</span> <br />
					<span>EEE = Tre førse i etternavn</span> <br />
					<span>FFF = Tre førse i fornavn</span>
				</div>
			</div>
		</div>
	);
};
export default Signature;
