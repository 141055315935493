import { Skeleton } from "@progress/kendo-react-indicators";
import { FC } from "react";
import { COLOR } from "utils/constant";

const DotCell: FC<{ value: number }> = ({ value }) => {
	const color = value === 1 ? COLOR.StatusGreen : "red";
	return (
		<td className="no-border">
			<div
				style={{
					width: 12,
					height: 12,
					borderRadius: 12,
					backgroundColor: color,
				}}
			></div>
			{/* <Skeleton
				animation={SkeletonAnimation}
				shape="circle"
				style={{
					width: 12,
					height: 12,

					backgroundColor: color,
				}}
			/> */}
		</td>
	);
};
export default DotCell;
