import { ApiResponse } from "interface/response"
import { AxiosApi } from "./index";



export async function getPdfApi(reportId: string, reportType: string) {
    const apiUri = "Pdf/Get"

    try {
        const registrations = await AxiosApi.get<string>(apiUri, {
            params: {
                reportType
                , reportId
            }
        })

        return registrations.data
    } catch (error) {
        throw error
    }
}

