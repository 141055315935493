import React, { FC, useEffect, useState } from "react";
import { loginApi } from "api/auth";
import CustomButton, { TextButton } from "ui/Button";
import { CustomInput, PasswordInput } from "ui/Input";
import { ICON_NAMES } from "utils/constant";
import { useNavigate } from "react-router-dom";

import "./style.css";
import { useAuthStore, useUserStore } from "../../store";
import ErrorInfo from "ui/ErrorInfo";
import { Checkbox } from "@progress/kendo-react-inputs";
import { useTranslation } from "react-i18next";

interface LoginForm {
	email: string;
	password: string;
	rememberMe: boolean;
}

const Login: FC<{}> = () => {
	const { t } = useTranslation();

	const [form, setForm] = useState<LoginForm>({
		email: "",
		password: "",
		rememberMe: false,
	});
	const [errorMessage, setErrorMessage] = useState("");
	const [errors, setErrors] = useState({ email: false, password: false });
	const { setAuth, ...auth } = useAuthStore();
	const { setStore } = useUserStore();
	const navigate = useNavigate();

	const submitLogin = () => {
		if (!form.password) {
			setErrorMessage("Password field is required");
			setErrors({ ...errors, password: true });
			return;
		}

		if (!form.email) {
			setErrorMessage("Email field is required");
			setErrors({ ...errors, email: true });
			return;
		}

		setErrors({ email: false, password: false });
		setErrorMessage("");
		loginApi(form.email, form.password, form.rememberMe)
			.then((response) => {
				// navigate(-1);
				setAuth({
					email: form.email,
					isLogin: true,
					twoFactorVerified: false,
					isVerified: false,
				});
				setStore(response.role, response.user);

				const previousUrl = sessionStorage.getItem("previousUrl");

				navigate(previousUrl ? previousUrl : "/");
			})
			.catch((err) => {
				if (err.status === 401) {
					setErrorMessage(
						"Password or email provided is invalid, try different one"
					);
				} else {
					setErrorMessage("Something  went wrong, try different one");
				}
				console.log(err);
			});
	};

	useEffect(() => {
		//	if (auth.isLogin) {
		//		navigate(-1); //
		//	}
	}, [auth.isLogin, navigate]);

	const emailChange = (value: string) => {
		setForm({ ...form, email: value });
	};

	const passwordChange = (value: string) => {
		setForm({ ...form, password: value });
	};

	return (
		<div
			style={{
				height: "100vh",
				backgroundColor: "#004959",
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
			}}
		>
			<div
				style={{
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
					width: "310px",
				}}
			>
				<div
					style={{
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
						marginBottom: "60px",
					}}
				>
					<img
						src={`/icon/${ICON_NAMES.SurofiTransparent}.svg`}
						alt="surofi"
						style={{ marginBottom: "10px" }}
					/>
					<span style={{ color: "white" }}>{t('MyPage')}</span>
				</div>

				<div
					style={{
						display: "flex",
						flexDirection: "column",
						width: "100%",
					}}
				>
					<CustomInput
						onChange={emailChange}
						value={form.email}
						placeholder="Email"
						style={{ marginBottom: "10px" }}
						className={
							errors.email ? "input-container error" : "input-container"
						}
					/>
					<PasswordInput
						value={form.password}
						setPasswordValidated={(isValidated: boolean) => { }}
						onChange={passwordChange}
						placeholder="Password"
						className="input-container"
						isError={errors.password}
					/>
					<div style={{ marginTop: "20px" }}>
						<Checkbox
							value={form.rememberMe}
							label={t('RememberUnit')}
							onChange={() =>
								setForm({ ...form, rememberMe: !form.rememberMe })
							}
						/>
					</div>
				</div>

				<ErrorInfo show={errors.email} text={errorMessage} />
				<ErrorInfo show={errors.password} text={errorMessage} />

				<div className="button-containter">
					<CustomButton
						label={t('Login')}
						bgColor="#006980"
						color="white"
						onClick={submitLogin}
					/>

					<CustomButton
						label={t('ForgotPassword')}
						bgColor="#004959"
						color="white"
						onClick={() => navigate("/auth/forgot-password")}
					/>
				</div>
				<div
					style={{
						display: "flex",
						gap: 10,
						justifyContent: "flex-end",
						width: "100%",
						marginTop: "20px",
					}}
				>
					{/*	<span style={{ color: "white" }}>Already have account ? </span>*/}
					{/*	<TextButton*/}
					{/*		text={"Signup"}*/}
					{/*		textStyle={{ color: "blue", marginTop: "3px" }} // Todo: add the right the color*/}
					{/*		onClick={() => navigate("/signup")}*/}
					{/*	/>*/}
				</div>
			</div>
		</div>
	);
};

export default Login;
