import { useEffect, useRef } from "react";

export const useEscapeKey = (onEscape: () => void) => {
  useEffect(() => {
    const handleEscapeKey = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        onEscape();
      }
    };

    document.addEventListener("keydown", handleEscapeKey);
    
    // Clean up the event listener on component unmount
    return () => {
      document.removeEventListener("keydown", handleEscapeKey);
    };
  }, [onEscape]);
};


export const useOutsideClick = (callback: (el : Node) => void) => {
    const ref = useRef<HTMLDivElement>(null);
  
    useEffect(() => {
      const handleClickOutside = (event: MouseEvent | TouchEvent) => {
      
        if (ref.current && !ref.current.contains(event.target as Node)) {
          callback(event.target as Node);
        }
      };
  
      document.addEventListener('mouseup', handleClickOutside);
  
  
      return () => {
        document.removeEventListener('mouseup', handleClickOutside);
      };
    }, [callback]);
  
    return ref;
  };

export const useClickOutside = (
  ref: React.RefObject<HTMLElement>,
  onClickOutside: ( el : HTMLElement) => void
) => {
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
  
      if (ref.current && ref.current instanceof HTMLElement && !ref.current.contains(event.target as Node)) {
        onClickOutside(event.target as HTMLElement);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    // Clean up the event listener on component unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, onClickOutside]);
};
