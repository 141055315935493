import { SortDescriptor } from "@progress/kendo-data-query";
import { camelCase, mapKeys, isArray, isObject } from 'lodash';

export const sortGrid = (sorts: { fieldName: string, dir?: 'asc' | 'desc' }[]): SortDescriptor[] => {
    return sorts.map(item => ({
        field: item.fieldName,
        dir: item.dir || 'asc'
    }));
};


/**
 * Recursively converts object keys from PascalCase to camelCase.
 * 
 * @param {any} obj - The object to convert.
 * @returns {any} - The object with camelCase keys.
 */
export const keysToCamel = (obj: any): any => {
    if (isArray(obj)) {
      return obj.map((value :any)=> keysToCamel(value));
    } else if (isObject(obj)) {
      return mapKeys(obj, (_value: any, key) => camelCase(key));
    }
    return obj;
  };