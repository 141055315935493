import { Card, CardBody } from "@progress/kendo-react-layout";
import { FC } from "react";
import { COLOR, Font } from "../utils/constant";
import styles from "../styles.module.css";

const ItemCard: FC<{
	title: string;
	subTitle: string;
	currentValue: string | number;
	valueUnit: string;
	changePercent: string | number;
	isPercentDecrease: boolean;
	style?: React.CSSProperties;
}> = ({
	title,
	subTitle,
	currentValue,
	changePercent,
	isPercentDecrease,
	valueUnit,
	...props
}) => {
	return (
		<div style={props.style}>
			<Card
				style={{
					width: "320px",
					borderRadius: "12px",
					fontFamily: Font.WorkSans,
				}}
			>
				<CardBody style={{ padding: "7px" }}>
					<div style={{ padding: "7px", paddingBottom: "0px" }}>
						<div
							className={styles.BoxHeadingSmall}
							style={{ marginBottom: "10px" }}
						>
							{title}
						</div>
						<div
							style={{
								display: "flex",
								justifyContent: "space-between",
								fontStyle: "italic",
								fontSize: "28px",
							}}
						>
							<div>
								<span> {currentValue}</span>
								<span style={{ lineHeight: "19px", fontSize: "16px" }}>
									{" "}
									{valueUnit}
								</span>
							</div>

							{isPercentDecrease ? (
								<span style={{ color: COLOR.StatusRed }}>
									{" "}
									-{changePercent}%
								</span>
							) : (
								<span style={{ color: COLOR.StatusGreen }}>
									{" "}
									+{changePercent} %
								</span>
							)}
						</div>

						<div
							style={{
								textAlign: "right",
								marginTop: "4px",
								fontFamily: Font.Roboto,
								fontStyle: "italic",
								fontSize: "10px",
							}}
						>
							<span
								style={{
									opacity: 0.5,
									color: "black",
								}}
							>
								{subTitle}
							</span>
						</div>
					</div>
				</CardBody>
			</Card>
		</div>
	);
};

export default ItemCard;
