import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { InfoPros } from "interface/index";
import React, { FC, useEffect } from "react";
import CustomButton from "./Button";

const Info: FC<InfoPros> = ({ title, show, actions, close, description }) => {
	useEffect(() => {
		const handleKeyPress = (event: KeyboardEvent) => {
			if (event.key === "Escape") {
				if (show) close();
			}
		};

		document.addEventListener("keydown", handleKeyPress);

		return () => {
			document.removeEventListener("keydown", handleKeyPress);
		};
	}, [close]);

	return (
		<div>
			{show && (
				<Dialog title={title} onClose={close}>
					<p
						style={{
							margin: "25px",
							textAlign: "center",
						}}
					>
						{description}
					</p>
					<DialogActionsBar>
						{actions.map((action, index) => (
							<div key={index}>
								<CustomButton
									label={action.text}
									onClick={action.btn}
									disabled={action.disabled || false}
									bgColor={action.bgColor}
									color={action.textColor}
								/>
							</div>
						))}
					</DialogActionsBar>
				</Dialog>
			)}
		</div>
	);
};
export default Info;
