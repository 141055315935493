import { ApiResponse } from "interface/response"
import { ILandingNote,  } from "interface/landingNote"
import { AxiosApi } from "./index"
import { FilterOption } from "interface/index"
import { LandingNoteLine } from "interface/landingNote"

export interface LandingNoteFilter extends  FilterOption{
    noteNumber?:  number,
    vesselName?: string
    vesselId?: string
    buyerId ?: number,
    buyerName?: string
}

export interface LandingNoteLineFilter{
    noteNumber?:  number,
    source? : number, 
    specieCode?: string
}

export  async function getLandingNotesApi(params : LandingNoteFilter ) {
    const apiUri = "LandingNote/GetList"
   try {
      const registrations = await AxiosApi.get<ApiResponse<ILandingNote[]>>(apiUri, {params:{
         ...params,
      }})
      return registrations.data
   } catch (error) {
      throw  error
   }
}
    
export  async function getLandingNoteLinesApi(params :LandingNoteLineFilter ) {
    const apiUri = "LandingNote/GetLines"
   try {
      const registrations = await AxiosApi.get<ApiResponse<LandingNoteLine[]>>(apiUri, {params:{
         ...params,
      }})
      return registrations.data
   } catch (error) {
      throw  error
   }
}
    

 