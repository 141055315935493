import {
	PanelBar,
	PanelBarItem,
	PanelBarSelectEventArguments,
} from "@progress/kendo-react-layout";
import {
	getUserOrganizationsApi,
	saveUserDefaultOrganizationApi,
} from "api/user";
import i18n from "i18n";
import { t } from "i18next";
import { OrganizationType } from "interface/user";
import React, { FC, useEffect } from "react";
import { useUserOrganizationsStore } from "store";
import { ICON_NAMES } from "utils/constant";

const UserOrganization: FC<{ onSelect: () => void }> = ({ onSelect }) => {
	const { organizations, setDefault, setOrganizations } =
		useUserOrganizationsStore();

	useEffect(() => {
		getUseItemsAccess();
	}, []);

	const getUseItemsAccess = () => {
		if (organizations.length > 0) {
			return;
		}
		getUserOrganizationsApi().then((data) => {
			setOrganizations(data.data);
		});
	};
	const handleSelect = (e: PanelBarSelectEventArguments) => {
		const index: number = e.target.props.index;
		const org = organizations[index];
		setDefault(org);
		onSelect();
		saveUserDefaultOrganizationApi(org)
			.then((_response) => {})
			.catch((err) => {
				console.log(err);
			});
	};
	const getIcon = (type: OrganizationType) => {
		if (type === OrganizationType.Buyer) {
			return ICON_NAMES.Buyer;
		}

		if (type === OrganizationType.Vessel) {
			return ICON_NAMES.Vessel;
		}

		if (type === OrganizationType.Terminal) {
			return ICON_NAMES.Terminal;
		}
	};

	return (
		<div
			style={{
				maxHeight: "400px",
				overflowY: "auto",
			}}
		>
			<PanelBar
				style={{
					right: "0px",
					backgroundColor: "transparent",
					borderRadius: "12px",
					fontSize: "15px",
					// width: 200,
					minWidth: "fit-content",
				}}
				onSelect={handleSelect}
				expandMode={"single"}
			>
				{organizations.map((item, index) => (
					<PanelBarItem
						title={item.displayName}
						imageUrl={`/icon/${getIcon(item.type)}.svg`}
						type={item.type}
						key={index}
						index={index}
					/>
				))}
			</PanelBar>
		</div>
	);
};

export default UserOrganization;
