import { CustomInput, PasswordInput } from "ui/Input";
import CustomText from "ui/Text";
import { useState } from "react";
import ErrorInfo from "ui/ErrorInfo";
import { COLOR, ICON_NAMES } from "utils/constant";
import CustomButton from "ui/Button";
import { useNavigate } from "react-router";

const ResetPassword = () => {
	const [form, setForm] = useState({ password: "", token: "" });

	const [errorMessage, setErrorMessage] = useState("");
	const [passwordValid, setPasswordValid] = useState(true);
	const [errors, setErrors] = useState({ token: false, password: false });
	const navigate = useNavigate();

	// Todo: how are we handle the reset of password ?

	const submit = () => {
		//resetPasswordApi()
	};

	return (
		<div
			style={{
				height: "100vh",
				backgroundColor: "#004959",
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
			}}
		>
			<div
				style={{
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
					width: "310px",
				}}
			>
				<div
					style={{
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
						marginBottom: "60px",
					}}
				>
					<img
						src={`/icon/${ICON_NAMES.SurofiTransparent}.svg`}
						alt="surofi"
						style={{ marginBottom: "10px" }}
					/>
					<span style={{ color: "white" }}>MinSide</span>
				</div>
				<div style={{ marginBottom: 40 }}>
					<CustomText text="Enter  the new password" color="white" />
				</div>

				<div
					style={{
						display: "flex",
						flexDirection: "column",
						width: "100%",
						gap: 20,
					}}
				>
					<PasswordInput
						value={form.password}
						onChange={(password) => setForm({ ...form, password })}
						placeholder="Password"
						isError={errors.password}
						setPasswordValidated={(isValidated: boolean) =>
							setPasswordValid(isValidated)
						}
					/>
					<CustomInput
						onChange={(token) => setForm({ ...form, token })}
						value={form.token}
						placeholder="Engangskode"
						isError={errors.token}
					/>
				</div>

				<ErrorInfo show={errors.password} text={errorMessage} />

				<div className="button-containter">
					<CustomButton
						label={"Send"}
						bgColor="#006980"
						color={COLOR.Grey98}
						onClick={submit}
						disabled={!passwordValid}
					/>

					<CustomButton
						label={"Logg inn?"}
						bgColor="#004959"
						color={COLOR.Grey98}
						onClick={() => navigate("/login")}
					/>
				</div>
			</div>
		</div>
	);
};

export default ResetPassword;
