import {
	DatePicker,
	DatePickerChangeEvent,
	DatePickerProps,
} from "@progress/kendo-react-dateinputs";
import { Skeleton } from "@progress/kendo-react-indicators";
import { DATE_FORMAT } from "utils/constant";
import { format } from "date-fns";
import { FC, useRef, useState } from "react";

//export function CustomDateInput(props: FieldRenderProps & DateInputProps) {
//  return (
//      <DateInput

//          {...props}
//      />
//  );
//}

// docs https://date-fns.org/v3.6.0/docs/format

export const GrideDateFormatter: FC<{
	date: string | Date;
	formatType: string;
	style?: React.CSSProperties;
}> = ({ date, formatType = "MM.dd.yyyy", style }) => {
	if (!date || date === undefined) {
		// shows loading cell if no data
		return (
			<td style={{ border: "none", ...style }}>
				{/* <Skeleton
					shape={"text"}
					style={{
						width: "100%",
					}}
				/> */}
			</td>
		);
	}

	const result = format(new Date(date), formatType);

	return <td style={{ border: "none" }}>{result}</td>;
};

export const CustomDatePicker: FC<DatePickerProps> = ({
	onChange,
	value,
	...props
}) => {
	const [_value, setValue] = useState(value);
	const dateRef = useRef<any>(null);
	const handleDate = (e: DatePickerChangeEvent) => {
		setValue(e.target.value);
		handleBlur(e);
	};

	const handleBlur = (e: any) => {
		if (!dateRef.current) {
			return;
		}

		const inputElement = dateRef.current as HTMLInputElement;
		if (!inputElement.validity?.valid || inputElement.validity?.valueMissing) {
			return;
		}
		if (onChange) {
			onChange(e);
		}
	};
	return (
		<DatePicker
			ref={dateRef}
			value={_value}
			//	onBlur={handleBlur}
			onChange={(e) => {
				handleDate(e);
			}}
			{...props}
		/>
	);
};
