import {
	GridColumnProps,
	GridCustomCellProps,
	GridHeaderCellProps,
} from "@progress/kendo-react-grid";
import { CLASSNAME } from "utils/constant";
import { FC } from "react";
//import styles from "styles.module.css";
import i18n from "../../../i18n";

let t = i18n.t;

export const AnnualStatementGridColumnItem = () => {
	const gridItems: GridColumnProps[] = [
		{
			//This wont show up
			field: "registrationDate",
			title: "Dato",
		},

		{
			field: "year",
			title: "År",
			width: "120px",
			cell: (props) => <NumericalCell {...props} unit={""} />,
			headerClassName: CLASSNAME.TableNumeric,
			className: `${CLASSNAME.TableNumeric} no-border`,
			headerCell: (props) => <NumericalHeader {...props} />,
		},
		{
			field: "customerBusinessRegisterNumber",
			title: "Resontro (Ledger Account)",
			width: "200px",
			cell: (props) => <NumericalCell {...props} unit={""} />,
			headerClassName: CLASSNAME.TableNumeric,
			className: `${CLASSNAME.TableNumeric} no-border`,
			headerCell: (props) => <NumericalHeader {...props} />,
		},

		{
			field: "customerName",
			title: "Fartøynavn",
			//width: "150px",
			headerClassName: "no-border",
		},
		{
			field: "vesselOwnerName",
			title: "Eiernavn",
			//width: "150px",
			headerClassName: "no-border",
		},
	];
	return gridItems;
};

export const NumericalHeader: FC<
	GridHeaderCellProps & { style?: React.CSSProperties }
> = (props) => {
	return <span style={{ ...props.style }}>{props.title}</span>;
};

export const NumericalCell: FC<GridCustomCellProps & { unit?: string }> = ({
	field,
	dataItem,
	unit,
	style,
}) => {
	let value;
	if (field) {
		value = dataItem[field];
	}

	return (
		<td style={{ textAlign: "end", border: "none", ...style }}>
			<span>{value}</span> {unit && <span>{unit}</span>}
		</td>
	);
};
