import { UserMenuItem } from "interface/userMenuItem";
import { ApiResponse } from "interface/response"
import { AxiosApi } from "./index";

export async function getMenuApi() {
    const apiUri = "/menu/get";

    try {
        const menuResponse = await AxiosApi.get<ApiResponse<UserMenuItem[]>>(apiUri, {})
        return menuResponse.data.data
    } catch (error) {
        throw error
    }
}
