import { GridCustomCellProps } from "@progress/kendo-react-grid";
import { FC, useState } from "react";
import { NumericFormat } from 'react-number-format';

const handleFocus = (event: any) => {
	event.target.select();
};

export const NumericalCell: FC<
	GridCustomCellProps & {
		unit?: string;
		showError?: boolean;
		decimalScale?: number;
		fixedDecimalScale?: boolean;
	}
	> = ({ field
		, dataItem
		, unit
		, tdProps
		, showError = false
		, decimalScale
		, fixedDecimalScale = false
		, ...props }) => {

	const [value, setValue] = useState(
		dataItem[field as any] ? dataItem[field as any] : 0
	);

	const onBlur = (e: any) => {
		if (!props.onChange) {
			return;
		}
		props.onChange({
			dataItem,
			dataIndex: props.dataIndex,
			syntheticEvent: e,
			field,
			value,
		});
	};

	const onValueChange = (values: any) => {
		setValue(values.floatValue);
	}

	return (
		<td {...tdProps} style={{ ...props.style }} className={props.className}>
			<NumericFormat
				className="grid-td-input k-input"
				width="90%"
				readOnly={!dataItem.inEdit}
				value={value}
				allowNegative={false}
				decimalScale={decimalScale}
				allowedDecimalSeparators={[".", ","]}
				fixedDecimalScale={fixedDecimalScale}
				decimalSeparator=","
				thousandSeparator=" "
				onFocus={handleFocus}
				onValueChange={onValueChange}
				onBlur={onBlur}
			/>
			{unit && <span>{unit}</span>}
		</td>
	);
};
