import React, { useEffect, useState } from "react";
import { Form, FormElement, FormRenderProps } from "@progress/kendo-react-form";

import styles from "styles.module.css";
import CustomButton from "ui/Button";
import { COLOR, ICON_NAMES } from "utils/constant";
import CustomStepper from "ui/CustomStepper";
import FormStepOne from "./form/StepOne";
import FormStepThree from "./form/StepThree";
import { getSalesNoteHeaderApi } from "api/salesNote";
import { useNavigate, useParams } from "react-router";
import { SalesNoteHead, SalesNoteLine } from "interface/salesNote";
import FormStepTwo from "./form/StepTwo";
// import PdfViewer from "component/pages/pdf/PdfViewer";

import Signature from "./form/Signature";
import PdfViewer from "./form/PdfViewer";
import { sendToSign } from "api/sign";
import { PageTypePath } from "utils/pages";

interface LineForm extends SalesNoteLine {
	id: number;
	inEdit: boolean;
}

interface SingerForm {
	vesselSigner: string;
	apprenticeSigner: string;
	buyerSigner: string;
	quotaTypeCode: string;
	showError: boolean;
}

function SalesNoteForm() {
	const [step, setStep] = useState(0);
	const [form, setForm] = useState<SalesNoteHead>(Object());
	const [lineForm, setLineForm] = useState<LineForm[]>([]);
	const [signers, setSigners] = useState<SingerForm>(Object);
	const { id, action } = useParams();
	const navigate = useNavigate();

	const maxStep = 4;

	const stepsItems = [
		{ label: "Fangstopplysninger", optional: false },
		{ label: "Varelinjer", optional: false },
		{ label: "Notater", optional: true },
		{ label: "Forhandsvis og fullfor", optional: true },
		{ label: "Signer", optional: true },
	];

	const onSubmit = () => {};

	const handleDelete = () => {};

	const handleFinish = () => {
		handleNext();
	};

	const handleNext = () => {
		setStep(step + 1);
	};

	useEffect(() => {
		if (!id) {
			return;
		}
		getSalesNoteHeaderApi(id as any)
			.then((data) => {
				setForm(data.data);
				setSigners({
					...signers,
					quotaTypeCode: data.data.quotaTypeCode as any,
				});
			})
			.catch((error) => {
				console.log(error);
			});
	}, []);

	useEffect(() => {
		if (!action) {
			return;
		}
		const _step = parseInt(action);
		if (!Number.isNaN(_step) && _step <= maxStep) {
			setStep(_step);
		}
	}, []);

	useEffect(() => {
		if (!action) {
			navigate(`/${PageTypePath.SalesNoteForm}/${id}/0`);
			return;
		}

		const _step = parseInt(action);

		if (!Number.isNaN(_step) && _step <= maxStep) {
			if (step !== _step) {
				navigate(`/${PageTypePath.SalesNoteForm}/${id}/${step}`, {
					replace: true,
				});
			}
		}
	}, [step]);

	useEffect(() => {
		if (signers.showError) {
			if (
				(signers.buyerSigner !== "" || signers.buyerSigner !== undefined) &&
				(signers.vesselSigner !== "" || signers.vesselSigner !== undefined)
			) {
				setSigners({ ...signers, showError: false });
			}
		}
	}, [signers]);

	const sendSigners = () => {
		if (!signers.buyerSigner || !signers.buyerSigner) {
			setSigners({ ...signers, showError: true });
			return;
		}
		sendToSign({
			...signers,
			documentNumber: 600000000011 as any, //form.noteNumber as any
		})
			.then((data) => {
				console.log(data);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	return (
		<div className={styles.PageContainer} style={{ height: "100%" }}>
			<legend
				className={styles.BoxHeadingLarge}
				style={{ paddingBottom: "20px" }}
			>
				New sales Note
			</legend>
			<CustomStepper
				steps={stepsItems}
				activeStep={step}
				setActiveStep={setStep}
			/>

			<div
				style={{
					display: "flex",
					flexDirection: "column",
					height: "100%",
					justifyContent: "space-between",
				}}
			>
				<div style={{ height: "526px" }}>
					<Form
						onSubmit={onSubmit}
						render={(formRenderProps: FormRenderProps) => (
							<FormElement style={{ width: "100%" }}>
								<fieldset className={"k-form-fieldset"}>
									{step === 0 && <FormStepOne form={form} setForm={setForm} />}
									{step === 1 && (
										<FormStepTwo
											setForm={setLineForm}
											form={lineForm}
											showRequirement={false}
											setMeetRequirement={function (
												meetRequirement: boolean
											): void {
												throw new Error("Function not implemented.");
											}}
										/>
									)}
									{step === 2 && <FormStepThree noteHeaderId={id as any} />}
									{step === 3 && <PdfViewer />}
									{step === maxStep && (
										<Signature setSigners={setSigners} signers={signers} />
									)}
								</fieldset>
							</FormElement>
						)}
					/>
				</div>

				<div>
					<div
						style={{
							display: "flex",
							justifyContent: "space-between",
							flex: 1,
						}}
					>
						<div style={{ display: "flex", gap: "10px" }}>
							<CustomButton
								onClick={handleDelete}
								label={"Slett utkast"}
								iconName={ICON_NAMES.Delete}
							/>

							<span style={{ paddingTop: "7px" }} className={styles.TableText}>
								Alle endringer lagret
							</span>
							<span style={{ paddingTop: "7px" }}>
								<img
									src={`/icon/checkmark_green.svg`}
									alt={"complete icon"}
									style={{ color: "red" }}
								/>
							</span>
						</div>

						<div style={{ display: "flex", gap: "10px" }}>
							{step > 0 && (
								<CustomButton
									onClick={() => setStep(step - 1)}
									label={"Tilbake"}
									iconName={ICON_NAMES.Prevous}
									iconRight={false}
								/>
							)}
							{step === 3 && (
								<>
									<CustomButton
										onClick={handleFinish}
										label={"Fullfør"}
										bgColor={COLOR.Green}
										color={"white"}
										iconName={ICON_NAMES.FinishTransparent}
									/>
								</>
							)}
							{step < 3 && (
								<CustomButton
									onClick={handleNext}
									label={"Neste"}
									bgColor={COLOR.Green}
									color={"white"}
									iconName={ICON_NAMES.NextWhite}
								/>
							)}
							{step === maxStep && (
								<CustomButton
									onClick={() =>
										setSigners({
											...Object(),
											quotaTypeCode: form.quotaTypeCode,
										})
									}
									label={"Ny signant +"}
									// bgColor={COLOR.Green}
									color={"black"}
									// iconName={ICON_NAMES.NextWhite}
								/>
							)}
							{step === maxStep && (
								<CustomButton
									onClick={sendSigners}
									label={"Send til signering"}
									bgColor={COLOR.Green}
									color={"white"}
									iconName={ICON_NAMES.CheckMark}
								/>
							)}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default SalesNoteForm;
