import { GridCellProps } from "@progress/kendo-react-grid";
import React, { FC } from "react";
import { ICON_NAMES } from "utils/constant";

export const CheckMarkCell: FC<
	GridCellProps & {
		checkField?: string;
		checkValue?: any;
		checked?: boolean;
	}
> = ({ ...props }) => {
	const dataItem = props.dataItem;
	let checked = dataItem[props.checkField as any] === props.checkValue;

	if (props.checked !== undefined) {
		checked = props.checked;
	}

	return (
		<td style={{ ...props.style }} className={props.className}>
			<img
				src={`/icon/${checked ? ICON_NAMES.CheckGreen : ICON_NAMES.Close}.svg`}
				alt={"check box"}
				style={{
					height: "16px",
					width: "16px",
					margin: "auto",
				}}
			/>
		</td>
	);
};
