import { DatePicker } from "@progress/kendo-react-dateinputs";
import { getBuyersFilterApi, getVesselFilter } from "api/common";
import { Vessel } from "interface/vessel";
import { FC } from "react";
import CustomButton from "ui/Button";
import CustomDropDownList from "ui/dropDown";
import { DATE_FORMAT, ICON_NAMES } from "utils/constant";
import { SalesNoteFilter } from "api/salesNote";
import { useTranslation } from 'react-i18next';

interface CustomProps {
	pageSize: number;
	setFilterOptions: (options: SalesNoteFilter) => void;
	filterOptions: SalesNoteFilter;
	exportAsPDF: () => void;
	exportAsExcel: () => void;
}
const Filter: FC<CustomProps> = ({
	setFilterOptions,
	filterOptions,
	exportAsPDF,
	...props
}) => {
	const { t } = useTranslation();

	const handleVessel = (vessel: Vessel) => {
		setFilterOptions({
			...filterOptions,
			vesselHullId: vessel.id as any,
			vesselId: vessel.id,
			vesselName: vessel.id ? vessel.nm : "",
			vesselRegistrationNumber: vessel.id ? vessel.rn : "",
			take: props.pageSize,
			skip: 0,
		});
	};

	return (
		<div
			style={{
				display: "flex",
				justifyContent: "space-between",
				flexWrap: "wrap",
			}}
		>
			<div style={{ display: "flex", gap: "10px", marginBottom: "15px" }}>
				<div style={{ width: "170px" }}>
					<DatePicker
						placeholder={t('FromDate')}
						label={t('FromDate')}
						format={DATE_FORMAT}
						className="height-inherit"
						value={filterOptions.fromDate}
						onChange={(e) =>
							setFilterOptions({
								...filterOptions,
								fromDate: e.target.value,
								take: props.pageSize,
								skip: 0,
							})
						}
					/>
				</div>
				<div style={{ width: "170px" }}>
					<DatePicker
						placeholder={t('ToDate')}
						label={t('ToDate')}
						className="height-inherit"
						format={DATE_FORMAT}
						value={filterOptions.toDate}
						onChange={(e) =>
							setFilterOptions({
								...filterOptions,
								toDate: e.target.value,
								take: props.pageSize,
								skip: 0,
							})
						}
					/>
				</div>

				<div style={{ width: "170px" }}>
					<CustomDropDownList
						data={[]}
						filterable={true}
						dataItemKey="id"
						textField="nm"
						label={t('Vessel')}
						value={{
							id: filterOptions.vesselId || null,
							nm: filterOptions.vesselName || t('ChooseVessel'),
						}}
						// label="Velg Fartøy"
						defaultItem={{ id: null, nm: t('All') }}
						onSelect={handleVessel}
						serverFilter={true}
						serverFilterApi={getVesselFilter}
					/>
				</div>

				<div style={{ width: "170px" }}>
					<CustomDropDownList
						onSelect={(buyer) =>
							setFilterOptions({
								...filterOptions,
								buyerId: buyer.id,
								buyerName: buyer.name,
								take: props.pageSize,
								skip: 0,
							})
						}
						data={[]}
						value={{
							id: filterOptions.buyerId || null,
							name: filterOptions.buyerName || t('ChooseBuyer'),
						}}
						dataItemKey="id"
						textField="name"
						label={t('Buyer')}
						defaultItem={{ id: null, name: t('All') }}
						className="height-inherit"
						filterable={true}
						serverFilter={true}
						serverFilterApi={getBuyersFilterApi}
					/>
				</div>
			</div>

			<div
				style={{
					display: "flex",
					justifyContent: "flex-end",
					flexGrow: 1,
					gap: "15px",
				}}
			>
				<CustomButton
					label={"PDF"}
					onClick={() => exportAsPDF()}
					iconName={ICON_NAMES.Download}
				/>
				<CustomButton
					label={"Excel"}
					onClick={() => props.exportAsExcel()}
					iconName={ICON_NAMES.Download}
				/>
			{/*	<CustomButton*/}
			{/*		label={"Tabell"}*/}
			{/*		onClick={() => {}}*/}
			{/*		iconName={ICON_NAMES.Settings}*/}
			{/*	/>*/}
			</div>
		</div>
	);
};

export default Filter;
