import { Routes, Route } from "react-router-dom";
import Login from "../component/auth/Login";
import OptVerification from "../component/auth/OptVerification";
import Home from "../component/home";
import ForgotPassword from "../component/auth/ForgotPassword";
import ResetPassword from "../component/auth/ResetPassword";
import PdfViewer from "../component/pages/pdf/PdfViewer";

const CustomRouter = () => {
	return (
		<Routes>
			<Route path={"/login"} element={<Login />} />
			<Route path={"/auth/2fa/:token"} element={<OptVerification />} />
			<Route path={"/auth/verification/:type"} element={<OptVerification />} />
			<Route path={"/auth/forgot-password"} element={<ForgotPassword />} />
			<Route path={"/auth/reset-password"} element={<ResetPassword />} />
			<Route path={"/pdf/:id/:type"} element={<PdfViewer />} />
			<Route path={"/"} element={<Home />} />

			<Route path={"/:path/:id/:action"} element={<Home />} />
			<Route path={"/:path/:id"} element={<Home />} />
			<Route path={"/:path"} element={<Home />} />
		</Routes>
	);
};

export default CustomRouter;
