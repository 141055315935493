import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import HeaderItem from "ui/Header";
import Pages from "./Pages";
import "./styles.css";
import SideMenuBar from "component/menu/SideMenu";
import { useLocation } from "react-router-dom";

const Home = () => {
	const [currentPage, setCurrentPage] = useState<string>("/");

	let { path } = useParams();
	const location = useLocation();
	const navigate = useNavigate();

	useEffect(() => {
		if (path) {
			if (currentPage === path) return;
			setCurrentPage(path);
		} else {
			//Todo: add not found page
			setCurrentPage("/");
		}

		// Store the current URL as the next previous URL
		sessionStorage.setItem("previousUrl", location.pathname);
	}, [path]);

	return (
		<div
			style={{
				backgroundColor: "#EDEFF0",
				height: "100vh",
				width: "100%",
			}}
		>
			<div style={{ height: "40px" }}>{""}</div>
			<div style={{ display: "flex", width: "100%" }}>
				<div style={{ width: "250px" }}>
					<div style={{ paddingLeft: "27px" }}>
						<img
							src={"/icon/surofi.svg"}
							alt="surofi"
							style={{ height: "28px" }}
							onClick={() => navigate("/")}
						/>
					</div>
					<div style={{ marginTop: "20px" }}>
						<SideMenuBar currentPage={currentPage} />
					</div>
				</div>

				<div
					style={{
						display: "flex",
						flexDirection: "column",
						height: "100%",
						boxSizing: "border-box",
						width: "calc(100%)",
					}}
				>
					<div style={{ paddingLeft: "30px" }}>
						<HeaderItem />
					</div>
					<div style={{ height: 20 }}>{""}</div>

					<div
						style={{
							// padding: "30px",
							// paddingTop: 0,
							width: "100%",
							height: "977px",
						}}
					>
						<Pages currentPage={currentPage} />
					</div>
				</div>
			</div>
		</div>
	);
};

export default Home;
