import React, { FC, useEffect } from "react";
import {
	Field,
	FieldRenderProps,
	FieldWrapper,
} from "@progress/kendo-react-form";
import {
	FormDropDownList,
	FormDatePicker,
	FormInput,
	FormDropDownListWithSearch,
	ItemLabel,
} from "ui/Topography";
import { useState } from "react";
import { ComboBox } from "@progress/kendo-react-dropdowns";
import {
	getCatchAreaApi,
	getCountriesApi,
	getFishingDepartmentIdNumbersApi,
	getFishingGearApi,
	getFoodSafetyApprovalNumberApi,
	getQuotaTypeApi,
	getSearchBusinessNumberApi,
	getSearchUsageApi,
	getTerminalsApi,
} from "api/common";
import { SalesNoteHead } from "interface/salesNote";
import { DROPDOWN_ITEMS, getLandingTypeField } from "utils/dropdown/items";
import { yesOrNoField } from "utils/dropdown/helper";
import { DATE_FORMAT } from "utils/constant";
import { t } from "i18next";
import {
	CatchArea,
	Country,
	DropDownItem,
	FishingGear,
} from "interface/common";
import { saveSalesNoteHead } from "api/salesNote";

const FormStepOne: FC<{
	form: SalesNoteHead;
	setForm: (form: SalesNoteHead) => void;
}> = ({ form, setForm }) => {
	const [buyersFd, setBuyersFd] = useState<DropDownItem[]>([]);
	const [quotaType, setQuotaType] = useState<DropDownItem[]>([]);
	const [countries, setCountries] = useState<Country[]>([]);
	const [business, setBusiness] = useState<DropDownItem[]>([]);
	const [foodSafetyApproveNumber, setFoodSafetyApproveNumber] = useState<
		DropDownItem[]
	>([]);

	// useEffect(() => {
	// 	if (form.buyerLegalEntityMainUnitNo) {
	// 	}
	// }, []);
	const salesNoteRefExist = form.referredNoteNumber !== null;

	const getQuotaTypeData = () => {
		getSearchUsageApi()
			.then((data) => {})
			.catch((err) => {
				console.log(err);
			});
		getQuotaTypeApi()
			.then((data) => {
				setQuotaType(data);
			})
			.catch((error) => {});
	};

	const getCountries = () => {
		getCountriesApi()
			.then((data) => {
				setCountries(data);
			})
			.catch((error) => {});
	};

	const getBusiness = () => {
		getSearchBusinessNumberApi(form.buyerLegalEntityMainUnitNo)
			.then((data) => {
				setBusiness(data);
			})
			.catch((error) => {});
	};

	const getFoodSafetyApprovalNumber = () => {
		getFoodSafetyApprovalNumberApi(form.buyerFdirId)
			.then((data) => {
				setFoodSafetyApproveNumber(data);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const fdNumber = () => {
		getFishingDepartmentIdNumbersApi(form.buyerLegalEntitySubUnitNo as any)
			.then((buyers) => {
				setBuyersFd(buyers);
			})
			.catch((error) => {
				console.log(error);
			});
	};

	const saveForm = (form: SalesNoteHead) => {
		setForm(form);
		form.noteStatus = 2;
		saveSalesNoteHead(form)
			.then((response) => {
				console.log(response);
			})
			.catch((err) => {
				console.log(err);
			});
	};
	return (
		<>
			<div
				style={{
					display: "grid",
					gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr 1fr",
					justifyContent: "space-between",
					gap: "20px",
					marginBottom: "25px",
				}}
			>
				<Field
					id={"Fangstdagbok"}
					name={"Fangstdagbok"}
					label={"Fangstdagbok*"}
					disabled={salesNoteRefExist}
					// placeholder={"example"}
					currentValue={form?.diaryNumber}
					type={"number"}
					component={FormInput}
					onChange={(e: any) => {
						saveForm({ ...form, diaryNumber: e.value });
					}}
				/>
				<Field
					id={"Tur_nummer*"}
					name={"Tur nummer*"}
					label={"Tur nummer*"}
					// placeholder={"emt"}
					disabled={salesNoteRefExist}
					component={FormInput}
					currentValue={form?.tripNumber}
					type={"number"}
					onChange={(e: any) => {
						saveForm({ ...form, tripNumber: e.value });
					}}
				/>

				<Field
					id={"ERS-meldingsnr"}
					name={"ERS-meldingsnr"}
					label={"ERS-meldingsnr. (POR)*"}
					textField={"field"}
					dataItemKey={"value"}
					disabled={salesNoteRefExist}
					// currentValue={data.buyer}
					data={DROPDOWN_ITEMS.yesOrNo}
					//	onChange={(e: any) => setForm({ ...form, diaryNumber: e.value })}
					component={FormDropDownList}
				/>
				<Field
					id={"FørsteFangstdato*"}
					name={"FørsteFangstdato"}
					label={"ForsteFangstdato*"}
					currentValue={form.firstCatchDate}
					disabled={salesNoteRefExist}
					placeholder=""
					format={DATE_FORMAT}
					onChange={(e: any) => {
						saveForm({ ...form, firstCatchDate: formatDate(e.value) });
					}}
					component={FormDatePicker}
				/>

				<Field
					id={"Siste_Fangstdato*"}
					name={"Siste_Fangstdato*"}
					label={"Siste Fangstdato*"}
					disabled={salesNoteRefExist}
					currentValue={form.lastCatchDate}
					onChange={(e: any) => {
						saveForm({ ...form, lastCatchDate: formatDate(e.value) });
					}}
					placeholder=""
					format={DATE_FORMAT}
					component={FormDatePicker}
				/>

				<Field
					id={"Fangst"}
					name={"Fangstår"}
					label={"Fangstår*"}
					disabled={salesNoteRefExist}
					placeholder={""}
					currentValue={form.catchYear}
					component={FormInput}
					type={"number"}
					onChange={(e: any) => {
						saveForm({ ...form, catchYear: e.value });
					}}
				/>
			</div>

			<div
				style={{
					display: "grid",
					gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr 1fr",
					justifyContent: "space-between",
					gap: "20px",
					marginBottom: "25px",
				}}
			>
				<Field
					id={"Mannskap"}
					name={"Mannskap"}
					label={"Mannskap (antall)*"}
					disabled={salesNoteRefExist}
					placeholder={""}
					component={FormInput}
					currentValue={form.crewSize}
					type={"number"}
					onChange={(e: any) => saveForm({ ...form, crewSize: e.value })}
				/>
				<Field
					id={"Herav_norsk_mannskap*"}
					name={"Herav_norsk_mannskap*"}
					label={"Herav norsk mannskap*"}
					placeholder={""}
					component={FormInput}
					disabled={salesNoteRefExist}
					currentValue={form.norwegianCrewSize}
					type={"number"}
					onChange={(e: any) =>
						saveForm({ ...form, norwegianCrewSize: e.value })
					}
				/>

				<Field
					id={"Norsk_skipper*"}
					name={"Norsk_skipper*"}
					label={"Norsk skipper ?*"}
					disabled={salesNoteRefExist}
					data={DROPDOWN_ITEMS.yesOrNo}
					textField={"field"}
					dataItemKey={"value"}
					// defaultItem={{ field: "Choose", value: "" }}
					currentValue={{
						value: form?.vesselOfficersNorwegian,
						field: yesOrNoField(form?.vesselOfficersNorwegian),
					}}
					onSelect={(data: { field: string; value: number }) =>
						saveForm({ ...form, vesselOfficersNorwegian: data.value })
					}
					component={FormDropDownList}
				/>
				<Field
					id={"Avgangsdatodato"}
					name={"Avgangsdatodato"}
					label={"Avgangsdatodato*"}
					currentValue={form.departureDate}
					disabled={salesNoteRefExist}
					placeholder={""}
					format={DATE_FORMAT}
					onChange={(e) => {
						saveForm({ ...form, departureDate: formatDate(e.value) });
					}}
					component={FormDatePicker}
				/>

				<Field
					id={"Ankomstdato*"}
					name={"Ankomstdato"}
					label={"Ankomstdato*"}
					disabled={salesNoteRefExist}
					currentValue={form.arrivalDate}
					value={form.arrivalDate}
					placeholder={""}
					format={DATE_FORMAT}
					onChange={(e) => {
						saveForm({ ...form, arrivalDate: formatDate(e.value) });
					}}
					component={FormDatePicker}
				/>

				<Field
					id={"Fangstdøgn"}
					name={"Fangstdøgn"}
					label={"Fangstdøgn"}
					placeholder={""}
					disabled={salesNoteRefExist}
					currentValue={form.daysFished}
					component={FormInput}
					type={"number"}
					onChange={(e) => {
						saveForm({ ...form, daysFished: e.value });
					}}
				/>
			</div>

			<div
				style={{
					display: "grid",
					gridTemplateColumns: "1fr 1fr ",
					justifyContent: "space-between",
					gap: "20px",
					marginBottom: "25px",
				}}
			>
				<Field
					id={"Redskap"}
					name={"Redskap"}
					label={t("FishingGear")}
					value={t("ChooseFishingGear")}
					filterable={true}
					textField={"displayName"}
					dataItemKey={"id"}
					disabled={salesNoteRefExist}
					filterApi={getFishingGearApi}
					defaultItem={{
						displayName: t("ChooseFishingGear"),
						id: null,
					}}
					currentValue={{
						id: form.fishingGearId,
						displayName: form.fishingGear,
					}}
					// currentValue={{
					// 	id: form.fishingGearId,
					// 	displayName: form?.fishingGear,
					// }}
					onSelect={(fishingGear: FishingGear) => {
						saveForm({
							...form,
							fishingGearId: fishingGear.id,
							fishingGear: fishingGear.name,
						});
					}}
					component={FormDropDownListWithSearch}
				/>
				<Field
					name={"Fangstfelt"}
					//label={"Fangstfelt"}
					label={t("CatchArea")}
					filterable={true}
					textField={"displayName"}
					dataItemKey={"id"}
					disabled={salesNoteRefExist}
					filterApi={getCatchAreaApi}
					defaultItem={{
						displayName: t("ChooseCatchArea"),
						id: null,
					}}
					currentValue={{
						id: form.catchField,
						displayName: `${form.catchAreaZone} | ${form.catchAreaQuotaZone} | ${form.catchArea}`,
					}}
					// className="error"
					onSelect={(catchArea: CatchArea) => {
						saveForm({
							...form,
							catchField: catchArea.code,
							catchAreaZone: catchArea.zone,
							catchAreaQuotaZone: catchArea.groundFDZone,
							catchArea: catchArea.name,
						});
					}}
					component={FormDropDownListWithSearch}
				/>
			</div>

			<div
				style={{
					display: "grid",
					gridTemplateColumns: "1fr 1fr 1fr 1fr ",
					justifyContent: "space-between",
					gap: "20px",
					marginBottom: "25px",
				}}
			>
				<Field
					id={"Kvotetype*"}
					name={"Kvotetype*"}
					label={"Kvotetype*"}
					textField={"name"}
					dataItemKey={"code"}
					disabled={salesNoteRefExist}
					data={quotaType}
					currentValue={{ code: form.quotaTypeCode, name: form.quotaType }}
					loadDataOnClicked={getQuotaTypeData}
					onChange={(e: { value: { code: string; name: string } }) => {
						saveForm({
							...form,
							quotaTypeCode: e.value.code,
							quotaType: e.value.name,
						});
					}}
					component={FormDropDownList}
				/>

				<Field
					id={"Kvotenasjon"}
					name={"Kvotenasjon"}
					label={"Kvotenasjon*"}
					placeholder={""}
					component={FormInput}
					currentValue={form.quotaNationCode}
					disabled={salesNoteRefExist}
					onChange={(e: any) => {
						saveForm({ ...form, quotaNationCode: e.value });
					}}
				/>

				<Field
					id={"Landingsnasjon"}
					name={"Landingsnasjon"}
					label={"Landingsnasjon*"}
					textField={"englishDescription"}
					dataItemKey={"code"}
					loadDataOnClicked={getCountries}
					data={countries}
					currentValue={{
						code: form.landingNationCode,
						englishDescription: form.landingNation,
					}}
					onChange={(e: { value: Country }) => {
						saveForm({
							...form,
							landingNationCode: e.value.code,
							landingNation: e.value.description,
						});
					}}
					component={FormDropDownList}
				/>
				<Field
					id={"Landingstype"}
					name={"Landingstype"}
					label={"Landingstype*"}
					data={DROPDOWN_ITEMS.landingType}
					textField={"field"}
					dataItemKey={"value"}
					currentValue={getLandingTypeField(form.partialLandingId as any)}
					component={FormDropDownList}
					onChange={(e: { value: { field: string; value: number } }) => {
						saveForm({ ...form, partialLandingId: e.value.value });
					}}
				/>
			</div>

			<div
				style={{
					display: "grid",
					gridTemplateColumns: "1fr 1fr 1fr 1fr ",
					justifyContent: "space-between",
					gap: "20px",
					marginBottom: "25px",
				}}
			>
				<Field
					id={"Forrige sted"}
					name={"Forrige sted"}
					label={"Forrige sted"}
					filterable={true}
					textField={"name"}
					dataItemKey={"code"}
					filterApi={getTerminalsApi}
					defaultItem={{
						name: "Choose",
						code: null,
					}}
					currentValue={{
						code: form.previousReceivingFacility,
						name: form.previousReceivingFacility,
					}}
					// className="error"
					onSelect={(data: DropDownItem) => {
						saveForm({ ...form, previousReceivingFacility: data.name });
					}}
					component={FormDropDownListWithSearch}
				/>
				<Field
					id={"Neste sted"}
					name={"Neste sted"}
					label={"Neste sted"}
					filterable={true}
					textField={"name"}
					dataItemKey={"code"}
					filterApi={getTerminalsApi}
					defaultItem={{
						name: "Choose",
						code: null,
					}}
					currentValue={{
						code: form.nextReceivingFacility,
						name: form.nextReceivingFacility,
					}}
					// className="error"
					onSelect={(data: DropDownItem) => {
						saveForm({ ...form, nextReceivingFacility: data.code });
					}}
					component={FormDropDownListWithSearch}
				/>
				<Field
					id={"Skipper"}
					name={"Skipper"}
					label={"Skipper*"}
					placeholder={""}
					component={FormInput}
					disabled={salesNoteRefExist}
					currentValue={form.vesselCaptainsName}
					onChange={(e: any) => {
						setForm({ ...form, vesselCaptainsName: e.value });
						//saveForm({ ...form, vesselCaptainsName: e.value })
					}}
					saveItem={(e: any) => {
						saveForm(form);
					}}
				/>
				<Field
					id={"Anvendelse"}
					name={"Anvendelse"}
					label={"Anvendelse*"}
					filterable={true}
					textField={"name"}
					dataItemKey={"code"}
					filterApi={getSearchUsageApi}
					defaultItem={{
						name: "Choose",
						code: null,
					}}
					currentValue={{ code: form.usageCode, name: form.usage }}
					// className="error"
					onSelect={(data: DropDownItem) =>
						saveForm({ ...form, usageCode: data.code, usage: data.name })
					}
					component={FormDropDownListWithSearch}
				/>
			</div>

			<div
				style={{
					display: "grid",
					gridTemplateColumns: "1fr 1fr 1fr",
					justifyContent: "space-between",
					gap: "20px",
					marginBottom: "25px",
				}}
			>
				<Field
					id={"Virksomhetsnummer"}
					name={"Virksomhetsnummer"}
					label={"Virksomhetsnummer"}
					data={business}
					textField={"name"}
					dataItemKey={"code"}
					currentValue={{
						code: form.buyerLegalEntitySubUnitNo?.trim(),
						name: form.buyerLegalEntitySubUnitName,
					}}
					loadDataOnClicked={getBusiness}
					onSelect={(data: DropDownItem) =>
						saveForm({
							...form,
							buyerLegalEntitySubUnitName: data.name,
							buyerLegalEntitySubUnitNo: data.code,
						})
					}
					component={FormDropDownList}
				/>
				<Field
					id={"Kjøper-ID"}
					name={"Kjøper-ID"}
					label={"F.Dir Kjoper-ID"}
					textField={"name"}
					data={buyersFd}
					dataItemKey={"code"}
					defaultItem={{
						name: "Choose",
						code: null,
					}}
					currentValue={{ id: form.buyerFdirId, name: form.buyerFdirId }}
					onSelect={(data: DropDownItem) =>
						saveForm({ ...form, buyerFdirId: data.code as any })
					}
					loadDataOnClicked={fdNumber}
					component={FormDropDownList}
				/>
				<Field
					id={"Godkjenningsnummer"}
					name={"Godkjenningsnummer"}
					label={"Godkjenningsnummer*"}
					data={foodSafetyApproveNumber}
					textField={"name"}
					dataItemKey={"code"}
					loadDataOnClicked={getFoodSafetyApprovalNumber}
					currentValue={{
						code: form.productionFacilityId,
						name: form.productionFacilityId,
					}}
					onSelect={(data: DropDownItem) => {
						saveForm({ ...form, productionFacilityId: data.code as any });
					}}
					component={ComboBoxItem}
				/>
			</div>
		</>
	);
};

export default FormStepOne;

const ComboBoxItem: FC<FieldRenderProps> = ({
	setValueS,
	currentValue,
	onChange,
	...props
}) => {
	const [value, setValue] = useState({
		code: currentValue.code,
		name: currentValue.name,
	});

	useEffect(() => {
		if (currentValue) {
			setValue({
				code: currentValue.code || "",
				name: currentValue.name || "",
			});
		}
	}, [currentValue]);
	const handleOnChange = (event: any) => {
		const ev = event.nativeEvent;
		if (!ev || ev.code === "Enter" || ev.type === "focusout") {
			props.onSelect(value);
			return;
		}
		props.onSelect(event.value);
	};
	const focusedItemIndex = (data: any[], inputText: any, textField: any) => {
		if (inputText !== value.code || inputText !== value.name) {
			setValue({ code: inputText, name: inputText });
		}
		let text = inputText.toLowerCase();
		return data.findIndex((item: any) =>
			String(textField ? item[textField] : item)
				.toLowerCase()
				.includes(text)
		);
	};

	<ComboBox focusedItemIndex={focusedItemIndex} />;
	return (
		<FieldWrapper style={props.wrapperStyle}>
			<ItemLabel id={props.id} text={props.label} />

			<div>
				<ComboBox
					data={props.data}
					value={value}
					onChange={handleOnChange}
					onBlur={handleOnChange}
					allowCustom={true}
					placeholder="Please select ..."
					onOpen={props.loadDataOnClicked}
					textField={props.textField}
					dataItemKey={props.dataItemKey}
					focusedItemIndex={focusedItemIndex}
					// {...props}
				/>
			</div>
		</FieldWrapper>
	);
};

// const formatDate = (date: Date) => {
// 	const _date = new Date(date);
// 	const _formattedDate = date.toISOString().split("T")[0];
// 	return _formattedDate;
// };

const formatDate = (date: Date) => {
	const year = date.getFullYear();
	const month = String(date.getMonth() + 1).padStart(2, "0");
	const day = String(date.getDate()).padStart(2, "0");

	// return `${day}.${month}.${year}`;
	return `${year}-${month}-${day}`;
};
