import { create } from 'zustand';
import { Auth } from '../interface/user';

interface AuthStore extends Auth {
  
    setAuth: (auth: Auth) => void;
    setLogout: ()  => void
}

//Todo: add actual field
export const useAuthStore = create<AuthStore>()((set) => ({
    isLogin: false,
    isVerified: false,
    email: "",
    twoFactorVerified: false,
    setAuth: (auth: any) => set({ ...auth }),
    setLogout: () => set({  isLogin: false,
        isVerified: false,
        email: "",
        twoFactorVerified: false})
}));

