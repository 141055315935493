import { AuthenticationResponse } from "interface/user";
import { AxiosApi } from "./index"
import { AxiosError} from 'axios';
import { VerificationType } from "interface/common";


enum VerificationError{
    InvalidToken = "InvalidToken" 
}


export const loginApi = async (email: string, password: string, rememberMe: boolean) => {

    try {
        const response = await AxiosApi.post("auth/login", {}, { params: { email, password,rememberMe } })
        return response.data
    } catch (error : any) {
        //Todo: handle possible expected login error 
        const err : AxiosError  = error

        throw err.response
    }
}

export const signupApi = async (email: string, password: string, roles: string[]) => {

    try {
        const response = await AxiosApi.post("auth/signup", {}, { params: { email, password,roles } })
        return response.data
    } catch (error : any) {
        //Todo: handle possible expected login error 
        const err : AxiosError  = error

        throw err.response
    }
}

export const requestTwoFactorAuthenticationCodeApi = async (email: string) => {

    try {
        const response = await AxiosApi.post("auth/2fa-request", {}, { params: { email, channel: "Phone" } })
        return response
    } catch (error : any) {
        const err : AxiosError  = error

        throw err.response

    }
}

export const twoFactorAuthenticationApi = async (email: string, token: string) => {

    try {
        const response = await AxiosApi.post("auth/2fa", {}, { params: { email, token} })
        return response
    } catch (error : any) {
        const err : AxiosError  = error

        throw err.response

    }
}

export const forgotPasswordApi = async (email: string) => {

    try {
        const response = await AxiosApi.post("auth/ForgotPassword", {}, { params: { email} })
        return response
    } catch (error : any) {
        const err : AxiosError  = error

        throw err.response

    }
} 

export const resetPasswordApi = async (email: string, token: string, password: string) => {

    try {
        const response = await AxiosApi.post("auth/ResetPassword", {}, { params: { email, token, password} })
        return response
    } catch (error : any) {
        const err : AxiosError  = error

        throw err.response

    }
} 


export const logoutApi = async () => {

    try {
        const response = await AxiosApi.post("auth/Logout", {}, { params: {  } })
        return response
    } catch (error: any) {
        const err: AxiosError = error

        throw err.response

    }
} 

export const cookieAuthApi = async () => {

    try {
        const response = await AxiosApi.get<AuthenticationResponse>("auth/Cookie" )
        return response.data
    } catch (error : any) {
        const err : AxiosError  = error

        throw err.response

    }
} 

export const userVerificationApi = async ({ token, userId,verificationType}:{userId : string, token: string, verificationType: VerificationType}) => {
  
    try {
        const response = await AxiosApi.post<AuthenticationResponse>("auth/email_verification", {}, {params:{
            token, userId,verificationType
        }} )
        return response.data
    } catch (error : any) {
        const err : AxiosError  = error
        throw err.response
    }
} 