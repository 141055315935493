export interface DropDownItem {
    id?: number,
    displayName: string
    name: string
    code?: string
}
export interface Specie extends Omit< DropDownItem, "id"> {
    fdCode: string

}
export interface FishingGear extends  DropDownItem {
  
}
export interface CatchArea extends DropDownItem {
    zone: string
    , groundFDZone: string
}

export interface Package extends  Omit<DropDownItem, "id">{
  
}

export interface Product {
    id: number;
    species: string;
    name: string;
    status: number;
    preservationCode: string;
    preservationType: string;
    condition: string;
    size: string;
    quality: string;
    faoCode: string;
    isNumberOfUnitsMandatory: boolean;
  }
  

 export  enum VerificationType {
	Email = "email",
	Phone = "phone",
}

export interface Country{
    id : number
    code : string
    description : string
    countryMunicipalNumber : string
    englishDescription : string

}