import {
	GridColumnProps,
	GridCustomCellProps,
	GridHeaderCellProps,
} from "@progress/kendo-react-grid";
import { CLASSNAME } from "utils/constant";
import { GrideDateFormatter } from "ui/Date";
import { FC } from "react";
//import styles from "styles.module.css";
import i18n from "../../../../i18n";
import { CheckMarkCell } from "ui/grid/CheckMarkCell";
import DotCell from "ui/grid/DotCell";

let t = i18n.t;

export const SalesNoteGridColumnItem = () => {
	const gridItems: GridColumnProps[] = [
		{
			//This wont show up
			field: "registrationDate",
			title: "Dato",
		},
		{
			field: "",
			title: "",
			width: "30px",
			headerClassName: "no-border",
			cell: (props) => <DotCell value={props.dataItem.status} />,
		},
		{
			field: "invoiceNumber",
			title: t("SettlementNumber"),
			// width: "100px",
			headerClassName: "no-border",
		},
		{
			field: "customerName",
			title: t("Vessel"),
			//width: "150px",
			headerClassName: "no-border",
		},
		{
			field: "invoiceDate",
			title: t("SettlementDate"),
			headerClassName: "no-border",
			cell: (props) => (
				<GrideDateFormatter
					date={props.dataItem.invoiceDate}
					formatType={"dd.MM.yyyy"}
				/>
			),
			// width: "110px",
		},

		{
			field: "printedDate",
			title: t("PrintDate"),
			headerClassName: "no-border",
			cell: (props) => (
				<GrideDateFormatter
					date={props.dataItem.printedDate}
					formatType={"dd.MM.yyyy"}
				/>
			),
		},
		{ field: "printedBy", title: t("PrintedBy") },
		{
			field: "ehfAccessResponse",
			title: "EHF",
			cell: (props) => (
				<CheckMarkCell
					{...props}
					checked={props.dataItem["invoiceSentDate"] !== null ? true : false}
				/>
			),
		},
		{
			field: "dueDate",
			title: t("PaymentDate"),
			headerClassName: "no-border",
			cell: (props) => (
				<GrideDateFormatter
					date={props.dataItem.dueDate}
					formatType={"dd.MM.yyyy"}
				/>
			),
		},

		{
			field: "grossValue",
			title: t("Value"),
			width: "120px",
			cell: (props) => <NumericalCell {...props} unit={"kr"} />,
			headerClassName: CLASSNAME.TableNumeric,
			className: `${CLASSNAME.TableNumeric} no-border`,
			headerCell: (props) => <NumericalHeader {...props} />,
		},
	];
	return gridItems;
};

export const NumericalHeader: FC<GridHeaderCellProps> = (props) => {
	return <span>{props.title}</span>;
};

export const NumericalCell: FC<GridCustomCellProps & { unit?: string }> = ({
	field,
	dataItem,
	unit,
}) => {
	let value;
	if (field) {
		value = dataItem[field];
	}

	return (
		<td style={{ textAlign: "end", border: "none" }}>
			<span>{value}</span> {unit && <span>{unit}</span>}
		</td>
	);
};
