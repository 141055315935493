import {
	Grid,
	GridColumn,
	GridColumnProps,
	GridDetailRowProps,
} from "@progress/kendo-react-grid";
import { NumericalCell, NumericalHeader } from "./GridColumns";
import { CLASSNAME, COLOR } from "utils/constant";
import { CustomRowRender } from "ui/grid/CustomGrid";
import { FC, useEffect, useRef, useState } from "react";
import { RegistrationLine } from "interface/registration";
import { getCatchRegistrationLineApi } from "api/registration";
import { useTranslation } from "react-i18next";

const DetailedPage = (prop: GridDetailRowProps) => {
	const { t } = useTranslation();

	const [data, setData] = useState<RegistrationLine[]>([]);

	useEffect(() => {
		const dataItem = prop.dataItem;

		if (!dataItem.registrationId) return;
		getCatchRegistrationLineApi(dataItem.registrationId, dataItem.status)
			.then((data) => {
				setData(data.data);
			})
			.catch((error) => {
				console.log(error);
			});
	}, []);

	const gridItems: GridColumnProps[] = [
		{
			//this will not be render
			field: "lineNumber",
			title: "Linje",
		},
		{
			field: "lineNumber",
			title: t("Line"),
			width: "80px",
			cell: (props) => <NumericalCell {...props} unit={""} />,
			headerClassName: CLASSNAME.TableNumeric,
			className: `${CLASSNAME.TableNumeric} no-border`,
			headerCell: (props) => <NumericalHeader {...props} />,
		},
		{ field: "product", title: t("Product") },
		{ field: "packaging", title: t("Packaging"), width: "auto" },
		{
			field: "numberOfPieces",
			title: t("Pieces"),
			cell: (props) => <NumericalCell {...props} unit={""} />,
			headerClassName: CLASSNAME.TableNumeric,
			className: `${CLASSNAME.TableNumeric} no-border`,
			headerCell: (props) => <NumericalHeader {...props} />,
		},
		{
			field: "liveWeightKg",
			title: t("Weight"),
			width: "120px",

			cell: (props) => <NumericalCell {...props} unit={"kg"} />,
			headerClassName: CLASSNAME.TableNumeric,
			className: `${CLASSNAME.TableNumeric} no-border`,
			headerCell: (props) => <NumericalHeader {...props} />,
		},
		{
			width: "40px",
			className: `${CLASSNAME.TableNumeric} no-border`,
		},
	];

	return (
		<div style={{ marginBottom: "15px", backgroundColor: "white" }}>
			<Grid
				style={{
					backgroundColor: COLOR.GridOpenedBody,
					height: " fit-content",
				}}
				rowRender={CustomRowRender}
				rowHeight={30}
				data={data}
				className="custom_detail_grid"
			>
				{gridItems.map((column, key) => (
					<GridColumn key={key} {...column} className="no-border" />
				))}
			</Grid>

			<div
				style={{
					paddingRight: "15px",
					paddingTop: "10px",
					display: "flex",
					justifyContent: "flex-end",
					flexGrow: 1,
					gap: "15px",
				}}
			></div>
		</div>
	);
};

export default DetailedPage;
