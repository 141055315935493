import { DatePicker } from "@progress/kendo-react-dateinputs";
import { FC, useState } from "react";
import CustomButton from "ui/Button";
import { DATE_FORMAT, ICON_NAMES } from "utils/constant";
import { useTranslation } from "react-i18next";
import { CustomInput } from "ui/Input";
import { InvoiceFilter } from "api/accounting";
import CustomDropDownList from "ui/dropDown";
import { getBuyersFilterApi } from "api/common";

interface CustomProps {
	pageSize: number;
	setFilterOptions: (options: InvoiceFilter) => void;
	filterOptions: InvoiceFilter;
	exportAsPDF: () => void;
	exportAsExcel: () => void;
}
const Filter: FC<CustomProps> = ({
	setFilterOptions,
	filterOptions,
	exportAsPDF,
	...props
}) => {
	const { t } = useTranslation();

	const [tempFilter, setTempFilter] = useState(filterOptions);

	return (
		<div
			style={{
				display: "flex",
				justifyContent: "space-between",
				flexWrap: "wrap",
			}}
		>
			<div style={{ display: "flex", gap: "10px", marginBottom: "15px" }}>
				<div style={{ width: "170px" }}>
					<DatePicker
						placeholder={t("FromDate")}
						label={t("FromDate")}
						format={DATE_FORMAT}
						className="height-inherit"
						value={filterOptions.fromDate}
						onChange={(e) =>
							setFilterOptions({
								...filterOptions,
								fromDate: e.target.value,
								take: props.pageSize,
								skip: 0,
							})
						}
					/>
				</div>
				<div style={{ width: "170px" }}>
					<DatePicker
						placeholder={t("ToDate")}
						label={t("ToDate")}
						className="height-inherit"
						format={DATE_FORMAT}
						value={filterOptions.toDate}
						onChange={(e) =>
							setFilterOptions({
								...filterOptions,
								toDate: e.target.value,
								take: props.pageSize,
								skip: 0,
							})
						}
					/>
				</div>
				<div style={{ width: "170px" }}>
					<CustomDropDownList
						onSelect={(buyer) =>
							setFilterOptions({
								...filterOptions,
								buyerId: buyer.id,
								buyerName: buyer.name,
								buyerLedgerAccountNumber: buyer.id,
								take: props.pageSize,
								skip: 0,
							})
						}
						data={[]}
						value={{
							id: filterOptions.buyerId || null,
							name: filterOptions.buyerName || t("ChooseBuyer"),
						}}
						dataItemKey="id"
						textField="name"
						label={t("Buyer")}
						defaultItem={{ id: null, name: t("All") }}
						className="height-inherit"
						filterable={true}
						serverFilter={true}
						serverFilterApi={getBuyersFilterApi}
					/>
				</div>
				<div style={{ width: "170px" }}>
					<CustomInput
						onChange={(e) => setTempFilter({ ...tempFilter, invoiceNumber: e })}
						label="Invoice number"
						placeholder="Invoice number"
						value={tempFilter.invoiceNumber}
						onBlur={(e) =>
							setFilterOptions({
								...filterOptions,
								invoiceNumber: tempFilter.invoiceNumber,
								take: props.pageSize,
								skip: 0,
							})
						}
					/>
				</div>
			</div>

			<div
				style={{
					display: "flex",
					justifyContent: "flex-end",
					flexGrow: 1,
					gap: "15px",
				}}
			>
				<CustomButton
					label={"PDF"}
					onClick={() => exportAsPDF()}
					iconName={ICON_NAMES.Download}
				/>
				<CustomButton
					label={"Excel"}
					onClick={() => props.exportAsExcel()}
					iconName={ICON_NAMES.Download}
				/>
			</div>
		</div>
	);
};

export default Filter;
