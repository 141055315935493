import {
	Grid,
	GridColumn,
	GridColumnProps,
	GridDetailRowProps,
} from "@progress/kendo-react-grid";
import { NumericalCell, NumericalHeader } from "./utils/GridColumns";
import { CLASSNAME, COLOR } from "utils/constant";
import { CustomRowRender } from "ui/grid/CustomGrid";
import { useEffect, useState } from "react";
import { getSalesNoteLinesApi } from "api/salesNote";
import { SalesNoteLine } from "interface/salesNote";
import { useTranslation } from "react-i18next";

const DetailedPage = (prop: GridDetailRowProps) => {
	const { t } = useTranslation();

	const [data, setData] = useState<SalesNoteLine[]>([]);

	useEffect(() => {
		const dataItem = prop.dataItem;

		if (!dataItem.noteNumber) return;

		getSalesNoteLinesApi({
			noteNumber: dataItem.noteNumber,
			source: dataItem.source,
		})
			.then((data) => {
				setData(data.data);
			})
			.catch((error) => {
				console.log(error);
			});
	}, []);

	const gridItems: GridColumnProps[] = [
		{
			//this will not be render
			field: "lineNumber",
			title: "Linje",
		},
		{
			//this will not be render
			// field: "lineNumber",
			title: t("Line"),
			width: "120px",
			cell: (props) => (
				<span style={{ paddingLeft: "10px" }}>{props.dataItem.lineNumber}</span>
			),
		},

		{ field: "product", title: t("Product") },
		{
			field: "registeredLiveWeightKg",
			title: t("RegisteredWeight"),
			cell: (props) => <NumericalCell {...props} unit={"kg"} />,
			headerClassName: CLASSNAME.TableNumeric,
			className: `${CLASSNAME.TableNumeric} no-border`,
			headerCell: (props) => <NumericalHeader {...props} />,
		},
		{
			field: "numberOfPieces",
			title: t("Pieces"),
			cell: (props) => <NumericalCell {...props} unit={""} />,
			headerClassName: CLASSNAME.TableNumeric,
			className: `${CLASSNAME.TableNumeric} no-border`,
			headerCell: (props) => <NumericalHeader {...props} />,
		},
		{
			field: "price",
			title: t("Price"),
			width: "120px",
			cell: (props) => <NumericalCell {...props} unit={"kr"} />,
			headerClassName: CLASSNAME.TableNumeric,
			className: `${CLASSNAME.TableNumeric} no-border`,
			headerCell: (props) => <NumericalHeader {...props} />,
		},
		{
			field: "netLiveWeightKg",
			title: t("Weight"),
			width: "120px",

			cell: (props) => <NumericalCell {...props} unit={"kg"} />,
			headerClassName: CLASSNAME.TableNumeric,
			className: `${CLASSNAME.TableNumeric} no-border`,
			headerCell: (props) => <NumericalHeader {...props} />,
		},
		{
			field: "netLiveWeightKg",
			title: t("Value"),
			width: "120px",

			cell: (props) => <NumericalCell {...props} unit={"kg"} />,
			headerClassName: CLASSNAME.TableNumeric,
			className: `${CLASSNAME.TableNumeric} no-border`,
			headerCell: (props) => <NumericalHeader {...props} />,
		},
		{
			width: "40px",
			className: `${CLASSNAME.TableNumeric} no-border`,
		},
	];
	// const openEditPage = () => {
	// 	if (isTemporaryNote) {
	// 		navigate(`/${PageTypePath.SalesNoteForm}/${prop.dataItem.noteNumber}`);
	// 	}
	// };
	// const openPdf = () => {
	// 	localStorage.setItem("noteNumber", prop.dataItem.noteNumber);

	// 	if (isTemporaryNote) {
	// 		window.open(
	// 			`/${PageTypePath.Pdf}/${prop.dataItem.noteNumber}/${PdfType.TemporarySalesNote}`,
	// 			"_blank"
	// 		);
	// 	} else {
	// 		window.open(
	// 			`/${PageTypePath.Pdf}/${prop.dataItem.noteNumber}/${PdfType.SalesNote}`,
	// 			"_blank"
	// 		);
	// 	}
	// };
	return (
		<div
			style={{ marginBottom: "15px", backgroundColor: COLOR.GridOpenedHeader }}
		>
			<Grid
				style={{
					backgroundColor: COLOR.GridOpenedHeader,
					height: " fit-content",
				}}
				rowRender={CustomRowRender}
				rowHeight={30}
				data={data}
				className="custom_detail_grid"
			>
				{gridItems.map((column, key) => (
					<GridColumn key={key} {...column} className="no-border" />
				))}
			</Grid>

			{/* <div
				style={{
					paddingRight: "15px",
					paddingTop: "20px",
					paddingBottom: "34px",
					display: "flex",
					justifyContent: "flex-end",
					flexGrow: 1,
					gap: "15px",
				}}
			>
				{isTemporaryNote && (
					<TextButton
						onClick={openEditPage}
						iconName={ICON_NAMES.Edit}
						text={t("EditSalesNote")}
					/>
				)}
				<TextButton
					onClick={openPdf}
					iconHeight={17}
					iconWidth={17}
					iconName={ICON_NAMES.Download}
					text={t("ViewSalesNote")}
				/>
			</div> */}
		</div>
	);
};

export default DetailedPage;
