import {
	Input,
	InputChangeEvent,
	InputProps,
} from "@progress/kendo-react-inputs";
import React, { FC, useState } from "react";
import { ICON_NAMES } from "../utils/constant";
import { GridCustomCellProps } from "@progress/kendo-react-grid";

interface CustomInputProp extends InputProps {
	onChange: (value: any) => void;
	isError?: boolean;
}

export const CustomInput: FC<CustomInputProp> = ({
	onChange,
	isError = false,
	...props
}) => {
	const handleOnChange = (e: InputChangeEvent) => {
		const value = e.target.value;
		onChange(value);
	};
	const className = isError ? "input-container error" : "input-container";
	return (
		<div>
			<Input
				style={{}}
				onChange={handleOnChange}
				value={props.value}
				className={className}
				{...props}
			/>
		</div>
	);
};

export const GridInput: FC<GridCustomCellProps> = ({
	td2Props,
	tdProps,
	...props
}) => {
	return <td {...tdProps}> {props.children}</td>;
};

export const PasswordInput: FC<
	CustomInputProp & { setPasswordValidated: (isValidated: boolean) => void }
> = ({
	onChange,
	type,
	isError,
	className,
	setPasswordValidated,
	...props
}) => {
	const [showPassword, setShowPassword] = useState(false);

	const togglePasswordVisibility = () => {
		setShowPassword(!showPassword);
	};

	const handleOnChange = (e: InputChangeEvent) => {
		const value = e.target.value;
		onChange(value);
	};
	//Todo: add password requirement validation
	const icon = showPassword ? ICON_NAMES.VisibilityOff : ICON_NAMES.Visibility;
	const _className = isError ? "input-container error" : "input-container";

	return (
		<div className={_className}>
			<Input
				style={{ border: "none" }}
				type={showPassword ? "text" : "password"}
				onChange={handleOnChange}
				value={props.value}
				{...props}
			/>

			<span onClick={togglePasswordVisibility} style={{ paddingRight: "3px" }}>
				<img src={`/icon/${icon}.svg`} alt={icon} />
			</span>
		</div>
	);
};
