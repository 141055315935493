import { Card, CardBody } from "@progress/kendo-react-layout";
import React, { useEffect, useRef, useState } from "react";
import { PageTypePath } from "utils/pages";
import style from "./style.module.css";
import styles from "styles.module.css";
//import TextButton from "ui/TextButton";
import { ICON_NAMES } from "utils/constant";
import { useNavigate } from "react-router";
import { Registration } from "interface/registration";
import {
	completeRegistrationApi,
	deleteRegistrationApi,
	getCatchRegistrationListApi,
} from "api/registration";
import { addMonths } from "date-fns";
import { Grid, GridCellProps, GridColumn } from "@progress/kendo-react-grid";
import {
	gridColumnItemsHandler,
	lastColumItems,
} from "component/pages/registration/utils/GridColumns";
import { CustomRowRender } from "ui/grid/CustomGrid";
import { lastColWithBtn } from "ui/grid/lastColumnts";
import Info from "ui/Info";
import { t } from "i18next";
import { TextButton } from "ui/Button";
import { useTranslation } from "react-i18next";
import { useUserOrganizationsStore } from "store";

function RegistrationCard() {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const [showConfirmation, setShowConfirmation] = useState(false);
	const [gridData, setGridData] = useState<Registration[]>();
	const deletingIdRef = useRef();
	const router = useNavigate();

	const { defaultOrg } = useUserOrganizationsStore();

	useEffect(() => {
		handleRequest();
	}, [defaultOrg]);

	const handleRequest = () => {
		console.log(defaultOrg);
		getCatchRegistrationListApi({
			take: 3,
			skip: 0,
			fromDate: addMonths(new Date(), -1),
			toDate: new Date(),
			buyerId: defaultOrg?.ledgerAccountNumber as any,
		})
			.then((response) => {
				setGridData(response.data);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const deleteConfirmation = (props: GridCellProps) => {
		deletingIdRef.current = props.dataItem.registrationId;
		setShowConfirmation(true);
	};

	const handleDelete = () => {
		if (!deletingIdRef.current) return;
		deleteRegistrationApi(deletingIdRef.current)
			.then((response) => {
				handleRequest();
				setShowConfirmation(false);
			})
			.catch((error) => {
				console.log(error);
			});
	};

	const handleFinish = (props: GridCellProps) => {
		completeRegistrationApi(props.dataItem.registrationId as any)
			.then((data) => {
				router(`/${PageTypePath.RegistrationList}`);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const editItem = (row: GridCellProps) => {
		let step = 0;

		if (row.dataItem.noteType === 0 && row.dataItem.numberOfLines > 0) {
			step = 1;
		}

		router(
			`/${PageTypePath.RegistrationForm}/${row.dataItem.registrationId}/${step}`,
			{
				state: {
					id: row.dataItem.registrationId,
					step: step,
					//	step: row.dataItem.numberOfLines > 0 ? 1 : 0,
				},
			}
		);
	};
	return (
		<div>
			<Card className={style.CardItem}>
				<CardBody>
					<div className={`${styles.BoxHeadingMedium} ${style.CardBodyHeader}`}>
						<div>{t("LastRegistrations")}</div>
						<div
							style={{
								display: "flex",
								justifyContent: "space-between",
								gap: "10px",
							}}
						>
							<div>
								<TextButton
									text={t("NewRegistration")}
									onClick={() => navigate(`/${PageTypePath.RegistrationForm}`)}
									iconName={ICON_NAMES.Plus}
								/>
							</div>
							<div>
								<TextButton
									text={t("OverviewRegistrationList")}
									onClick={() => navigate(`/${PageTypePath.RegistrationList}`)}
									iconName={ICON_NAMES.Arrow}
								/>
							</div>
						</div>
					</div>
					<div>
						<Grid
							style={{ height: 180 }}
							rowHeight={38}
							rowRender={CustomRowRender}
							data={gridData}
							// data={gridData.slice(0, gridData.length)}
							//className={styles.tableColgroupColFirstChild}
							className={
								styles.tableColgroupColFirstChild + " custom_detail_grid"
							}
						>
							{gridColumnItemsHandler().map((column, key) => (
								<GridColumn key={key} {...column} className="no-border" />
							))}
							{/*//todo: Redesign this */}
							<GridColumn
								{...lastColWithBtn({
									width: "150px",
									showDetails: false,
									items: [
										{
											iconName: ICON_NAMES.Edit,
											onclick: editItem,
											showConditionValue: 0,
											conditionField: "status",
										},
										{
											iconName: ICON_NAMES.Checkbox,
											onclick: handleFinish,
											showConditionValue: 0,
											conditionField: "status",
										},
									],
									showDelete: true,
									deleteItem: {
										btn: deleteConfirmation,
										showConditionValue: 0,
										conditionField: "status",
										style: { backgroundColor: "transparent" },
									},
								})}
							/>
						</Grid>
						<Info
							close={() => setShowConfirmation(false)}
							title={t("DeleteRegistration")}
							show={showConfirmation}
							description={t("DeleteRegistrationConfirmation")}
							actions={[
								{
									text: t("Cancel"),
									btn: () => setShowConfirmation(false),
								},
								{
									text: t("Yes"),
									bgColor: "red",
									textColor: "white",
									btn: handleDelete,
								},
							]}
						/>
					</div>
				</CardBody>
			</Card>
		</div>
	);
}

export default RegistrationCard;
