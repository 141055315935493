import { getInvoicedYearPaymentApi } from "api/accounting";
import { InvoiceYearlyPayment } from "interface/accounting";
import React, { FC, useEffect, useState } from "react";
import { COLOR } from "utils/constant";
import styles from "styles.module.css";
import { Loader } from "@progress/kendo-react-indicators";

const startYear = 2000;

const InvoiceHeader = () => {
	const currentYear = new Date().getFullYear();
	const [yearlyInvoice, setYearlyInvoice] = useState<InvoiceYearlyPayment>();

	const years = [];

	for (let year = startYear; year <= currentYear; year++) {
		years.push(year);
	}

	useEffect(() => {
		handleGetData(currentYear);
	}, []);

	const handleYearChange = (event: any) => {
		handleGetData(event.target.value);
	};

	const handleGetData = (year: number) => {
		getInvoicedYearPaymentApi(year)
			.then((response) => {
				setYearlyInvoice(response.data);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	return (
		<div
			style={{
				display: "flex",
				flexDirection: "column",
				gap: "20px",
				padding: "20px",
				backgroundColor: COLOR.Grey98,
				marginBottom: "40px",
			}}
		>
			<div style={{ display: "flex", justifyContent: "space-between" }}>
				<legend style={{}} className={styles.BoxHeadingMedium}>
					{"Hittil i år oppsummert"}
				</legend>
			</div>
			<div style={{ display: "flex", gap: "20px" }}>
				<div>
					<select
						value={yearlyInvoice?.year}
						onChange={handleYearChange}
						style={{
							border: "none",
							background: "transparent",
							outline: "none",
						}}
						className={styles.TextRobot}
					>
						{years.map((year) => (
							<option key={year} value={year}>
								{year}
							</option>
						))}
					</select>
				</div>
				{yearlyInvoice ? (
					<div style={{ display: "flex", gap: "20px" }}>
						<Item
							name={"Betalt (eks MVA)"}
							amount={yearlyInvoice.totalNetValue}
						/>
						<Item
							name={"Restbeløp (eks MVA)"}
							amount={yearlyInvoice.totalRemainingValue}
						/>
						<Item name={"Merverdiavgift"} amount={yearlyInvoice.totalVat} />
						{/* <Item
							name={"Renteinntekt"}
							amount={yearlyInvoice.totalGrossValue}
						/> */}
						{/* <Item name={"Renteutgift"} amount={yearlyInvoice.totalGrossValue} /> */}
					</div>
				) : (
					<div>
						<Loader size="large" type={"pulsing"} />
					</div>
				)}
			</div>

			<div>
				<span
					style={{ color: COLOR.TextLight, fontStyle: "italic" }}
					className={styles.TextRobot}
				>
					til og med siste registrerte avregning
				</span>
			</div>
		</div>
	);
};

export default InvoiceHeader;

const Item: FC<{ name: string; amount: number }> = ({ name, amount }) => {
	return (
		<div
			className={styles.TextRobot}
			style={{ fontSize: "16px", lineHeight: "19px" }}
		>
			<span>{name}</span> :{" "}
			<span
				style={{
					color: "#006980",

					fontWeight: "bold",
				}}
			>
				{amount}
			</span>
		</div>
	);
};
