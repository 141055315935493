import React, { FC, useEffect, useState } from "react";
import styles from "styles.module.css";
import {
	Grid,
	GridColumn,
	GridProps,
	GridRowProps,
} from "@progress/kendo-react-grid";
import { Skeleton } from "@progress/kendo-react-indicators";
import { GridConfig } from "utils/index";
import { Pager, PagerProps } from "@progress/kendo-react-data-tools";
import { COLOR } from "utils/constant";

const CustomGrid: React.FC<GridConfig & GridProps> = ({
	skip = 0,
	take = 10,
	pageSize = 10,

	gridColumns = [],
	onExpandChange,
	data = [],
	editField = "inEdit",
	showRowBgColor,
	handelShowRowBgColor,

	...props
}) => {
	//const [sortItem, setSortItem] = useState<SortDescriptor[]>(sort);
	return (
		<div style={{ overflowX: "auto", width: "fit-content" }}>
			<Grid
				rowRender={(row, props) =>
					CustomRowRender(row, props, handelShowRowBgColor)
				}
				data={data.slice(skip, take)}
				// data ={data}

				//take={take}
				skip={skip}
				cellRender={loadingCell}
				editField={editField}
				onExpandChange={onExpandChange}
				className={props.className}
				//onPageChange={pageChange}
				{...props}
				// pager={() => (
				//   <CustomPager
				//     total={data.length}
				//     skip={page.skip}
				//     take={page.take}
				//     pageSizes={props.pageSizes}
				//     onPageChange={pageChange}
				//   />
				// )}
			>
				{gridColumns.map((GridItem, index) => (
					<GridColumn
						{...GridItem}
						key={index}
						// {...(GridItem as any)}
						// className={GridColumn.propTypes?.className + " no-border"}
					/>
				))}
			</Grid>
		</div>
	);
};

export const CustomGridWithAutoLoad: React.FC<
	GridConfig &
		GridProps & {
			dataApi?: (filter: any) => Promise<any[]>;
			filterParams?: any;
			totalData?: number;
		}
> = ({
	pageSize = 25,
	dataApi,
	filterParams,
	gridColumns = [],
	onExpandChange,
	onPageChange,
	data = [],
	totalData = 500,
	editField = "inEdit",
	...props
}) => {
	const [griData, setGriData] = useState<any[]>(data);
	const [page, setPage] = useState({ take: pageSize, skip: 0 });
	const requestInProgress = React.useRef(false);
	const filterRef = React.useRef({ take: pageSize, skip: 30, requestSkip: 0 });
	useEffect(() => {
		if (dataApi) {
			dataApi({ ...filterParams, take: pageSize, skip: 0 })
				.then((data) => {
					setGriData(data);
				})
				.catch((error) => {
					console.log(error);
				});
		}
	}, []);

	const handlePageChange = (skip: number = 0) => {
		console.log(skip);
		if (filterRef.current.skip >= totalData) {
			return;
		}
		setPage({ ...page, skip });

		if (requestInProgress.current) {
			return;
		}

		if (dataApi) {
			if (griData.length - skip > pageSize + 15) {
				//dont make request
				return;
			}
			requestInProgress.current = true;

			filterRef.current.skip = pageSize + filterRef.current.skip;

			dataApi({
				...filterParams,
				take: pageSize,
				skip: filterRef.current.skip,
			})
				.then((data) => {
					setGriData([...griData, ...data]);
					requestInProgress.current = false;
					filterRef.current.requestSkip = filterRef.current.skip;
				})
				.catch((error) => {
					console.log(error);
				});
		}
	};
	return (
		<div>
			<Grid
				rowRender={rowRender}
				data={griData.slice(page.skip, pageSize + page.skip)}
				// data ={data}
				// dataItemKey={"registrationId"}
				// take={skip + pageSize}
				skip={page.skip}
				pageSize={pageSize}
				total={totalData}
				editField={editField}
				onExpandChange={onExpandChange}
				onPageChange={(e) => {
					handlePageChange(e.page.skip);
				}}
				{...props}
				// pager={() => (
				//   <CustomPager
				//     total={data.length}
				//     skip={page.skip}
				//     take={page.take}
				//     pageSizes={props.pageSizes}
				//     onPageChange={pageChange}
				//   />
				// )}
			>
				{gridColumns.map((GridItem, index) => (
					<GridColumn
						key={index}
						{...(GridItem as any)}
						id={index}
						className="no-border"
					/>
				))}
			</Grid>
		</div>
	);
};

export default CustomGrid;

// const CustomPager: React.FC<PagerProps> = (props) => {
// 	return (
// 		<Pager
// 			skip={props.skip}
// 			take={props.take}
// 			total={props.total}
// 			buttonCount={props.buttonCount}
// 			info={true}
// 			type={props.type}
// 			pageSizes={props.pageSizes ? props.pageSizes : undefined}
// 			previousNext={true}
// 			onPageChange={props.onPageChange}
// 		/>
// 	);
// };

export const CustomRowRender = (
	//row: React.ReactElement<HTMLTableRowElement> as any,
	row: any,
	props: GridRowProps,
	handelShowRowBgColor?: (props: GridRowProps) => string
) => {
	let bgColor = "";
	const data = props.dataItem;
	const isExpanded = data.expanded;
	let color = COLOR.TextLight;
	if (!data.status || data.status === 0) {
		color = COLOR.Grey20;
	}
	if (handelShowRowBgColor) {
		bgColor = handelShowRowBgColor(props);
	}

	if (isExpanded) {
		bgColor = COLOR.GridOpenedHeader;
	}
	return (
		<tr
			{...row.props}
			className={`${styles.TableText}  ${styles.TextRobot} grid-row`}
			style={{
				backgroundColor: bgColor,
				color,
			}}
		></tr>
	);
};

const loadingCell = (tdElement: any, props: any) => {
	const field = props.field || "";
	if (props.dataItem[field] === undefined) {
		// shows loading cell if no data
		return (
			<td>
				{" "}
				<Skeleton
					shape={"text"}
					style={{
						width: "100%",
					}}
				/>
			</td>
		);
	} // default rendering for this cell

	return tdElement;
};

function rowRender(trElement: any, props: any) {
	const available = !props.dataItem.Discontinued;
	const green = {
		backgroundColor: "rgb(55, 180, 0,0.32)",
	};
	const red = {
		backgroundColor: "rgb(243, 23, 0, 0.32)",
	};
	const trProps = {
		style: available ? green : red,
		maxHeight: "38px",
	};
	return React.cloneElement(
		trElement,
		{
			...trProps,
		},
		trElement.props.children
	);
}
