import React from "react";

const CustomStepper = ({
	steps,
	activeStep,
	setActiveStep,
}: {
	steps: { label: string; optional?: boolean }[];
	activeStep: number;
	setActiveStep: (step: number) => void;
}) => {
	return (
		<>
			<div className="stepper">
				{steps.map((step, index) => (
					<React.Fragment key={index}>
						{index < activeStep ? (
							<div
								className={`step complete`}
								onClick={() => setActiveStep(index)}
							>
								<div className={`step-index complete`}>
									<img src={`/icon/checkmark.svg`} alt={"complete icon"} />
								</div>
							</div>
						) : (
							<div
								className={`step ${index === activeStep ? "active" : ""}`}
								onClick={() => setActiveStep(index)}
							>
								<div
									className={`step-index ${
										index === activeStep ? "active" : ""
									}`}
								>
									{index + 1}
								</div>
							</div>
						)}

						{index < steps.length - 1 && (
							<div
								className={`connector ${index < activeStep ? "active" : ""}`}
							></div>
						)}
					</React.Fragment>
				))}
			</div>
			<div
				style={{
					display: "flex",
					justifyContent: "space-between",
					paddingBottom: "30px",
					paddingRight: "25px",
				}}
			>
				{steps.map((step, index) => (
					<div key={index} className={`step-name`} style={{ width: "50px" }}>
						{step.label}
					</div>
				))}
			</div>
		</>
	);
};

export default CustomStepper;
