import { Button } from "@progress/kendo-react-buttons";
import React, { FC } from "react";
import style from "../styles.module.css";
import { COLOR } from "../utils/constant";

export const TextButton: FC<{
	iconName?: string;
	iconWidth?: number;
	iconHeight?: number;
	text: string;
	textStyle?: React.CSSProperties;
	iconStyle?: React.CSSProperties;
	onClick: () => void;
}> = ({
	iconName,
	onClick,
	text,
	iconHeight = 15,
	iconWidth = 15,
	textStyle,
	iconStyle,
}) => {
	const handleClick = () => {
		if (onClick) {
			onClick();
		}
	};
	return (
		<div className={style.TextButton} onClick={handleClick}>
			<div style={{ marginTop: "-3px", ...textStyle }}>{text}</div>
			<div>
				{iconName && (
					<img
						src={`/icon/${iconName}.svg`}
						alt={text}
						style={{
							width: `${iconWidth}px`,
							height: `${iconHeight}px`,
							...iconStyle,
						}}
					/>
				)}
			</div>
		</div>
	);
};

const CustomButton: FC<{
	iconName?: string;
	label: string;
	onClick: () => void;
	disabled?: boolean;
	bgColor?: string;
	color?: string;
	iconRight?: boolean;
	className?: string;
	gap?: string;
}> = ({
	iconName,
	onClick,
	label,
	disabled = false,
	bgColor = COLOR.Grey98,
	color,
	iconRight = true,
	className,
	gap = "8px",
}) => {
	const handleClick = () => {
		if (onClick) {
			onClick();
		}
	};
	return (
		<Button
			style={{
				color: color,
				backgroundColor: bgColor,
				borderColor: "transparent",
			}}
			className={`${style.ButtonItem} ${className}`}
			onClick={handleClick}
			disabled={disabled}
		>
			<div style={{ display: "flex", gap }} className={style.ButtonLabel}>
				{iconRight ? (
					<>
						{label}

						{iconName && (
							<img src={`/icon/${iconName}.svg`} alt={iconName} style={{}} />
						)}
					</>
				) : (
					<>
						{iconName && <img src={`/icon/${iconName}.svg`} alt={iconName} />}
						{label}
					</>
				)}
			</div>
		</Button>
	);
};

export default CustomButton;
