import React, { useRef, useState } from "react";

import styles from "styles.module.css";
import DetailedPage from "./DetailPage";
import { gridColumnItemsHandler } from "./GridColumns";
import Filter from "./Filter";
import { GridPDFExport } from "@progress/kendo-react-pdf";
import { ExcelExport } from "@progress/kendo-react-excel-export";

import "./styles.css";
import { addMonths } from "date-fns";
import OverLayLoading from "ui/Loading";
import { getLandingNotesApi, LandingNoteFilter } from "api/landingNote";
import GridCustomPage from "ui/grid/GridPage";
import { useTranslation } from "react-i18next";
import { GridCellProps } from "@progress/kendo-react-grid";
import { PageTypePath } from "utils/pages";
import { PdfType } from "utils/PdfType";
import { ICON_NAMES } from "utils/constant";

const pageSize = 60;

const LandingNoteList = () => {
	const { t } = useTranslation();

	const overlayRef = useRef<HTMLDivElement>(null);

	const gridRef = useRef<HTMLDivElement>(null);

	const [filterOptions, setFilterOptions] = useState<LandingNoteFilter>({
		take: pageSize,
		skip: 0,
		fromDate: addMonths(new Date(), -1),
		toDate: new Date(),
	});

	const pdfRef = useRef<GridPDFExport>(null);
	const excelRef = useRef<ExcelExport>(null);

	const exportGridAsPDF = () => {
		if (pdfRef.current !== null) {
			pdfRef.current.save();
		}
	};

	const exportGridAsExcel = () => {
		if (excelRef.current !== null) {
			excelRef.current.save();
		}
	};
	const openPdf = (prop: GridCellProps) => {
		localStorage.setItem("noteNumber", prop.dataItem.noteNumber);
		const isTemporaryNote = prop.dataItem.source === 0;
		if (isTemporaryNote) {
			window.open(
				`/${PageTypePath.Pdf}/${prop.dataItem.noteNumber}/${PdfType.TemporaryLandingNote}`,
				"_blank"
			);
		} else {
			window.open(
				`/${PageTypePath.Pdf}/${prop.dataItem.noteNumber}/${PdfType.LandingNote}`,
				"_blank"
			);
		}
	};
	return (
		<div className={styles.PageContainer}>
			<div ref={overlayRef} style={{ display: "none" }}>
				<OverLayLoading />
			</div>
			<div style={{ display: "flex", justifyContent: "space-between" }}>
				<legend
					className={styles.BoxHeadingLarge}
					style={{ paddingBottom: "20px" }}
				>
					{t("LandingNotes")}
				</legend>
			</div>

			<div>
				<Filter
					setFilterOptions={setFilterOptions}
					filterOptions={filterOptions}
					exportAsPDF={exportGridAsPDF}
					exportAsExcel={exportGridAsExcel}
					pageSize={pageSize}
				/>
			</div>
			<div style={{}} ref={gridRef}>
				<GridCustomPage
					dataKey="noteNumber"
					gridColumns={gridColumnItemsHandler()}
					detailPage={DetailedPage}
					filter={filterOptions}
					exportGridAsPDFRef={pdfRef}
					lastColWidth="100px"
					lastColBtns={[
						{
							iconName: ICON_NAMES.Edit,
							alwaysShow: true,
							conditionField: "source",
							showConditionValue: 0,
							onclick: () => {},
						},
						{
							iconName: ICON_NAMES.Download,
							alwaysShow: true,
							onclick: openPdf,
						},
					]}
					exportGridAsExcelRef={excelRef}
					apiMethod={getLandingNotesApi}
				/>
			</div>
		</div>
	);
};

export default LandingNoteList;
