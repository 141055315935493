import {
	Grid,
	GridColumn,
	GridColumnProps,
	GridDetailRowProps,
} from "@progress/kendo-react-grid";
import { NumericalCell, NumericalHeader } from "./GridColumns";
import { CLASSNAME, COLOR } from "utils/constant";
import { CustomRowRender } from "ui/grid/CustomGrid";
import { useEffect, useState } from "react";
import { getLandingNoteLinesApi } from "api/landingNote";
import { LandingNoteLine } from "interface/landingNote";
import { useTranslation } from "react-i18next";

const DetailedPage = (prop: GridDetailRowProps) => {
	const { t } = useTranslation();

	const [data, setData] = useState<LandingNoteLine[]>([]);

	useEffect(() => {
		const dataItem = prop.dataItem;

		if (!dataItem.noteNumber) return;
		getLandingNoteLinesApi({
			noteNumber: dataItem.noteNumber,
			source: dataItem.source,
		})
			.then((data) => {
				setData(data.data);
			})
			.catch((error) => {
				console.log(error);
			});
	}, []);

	const gridItems: GridColumnProps[] = [
		{
			//this will not be render
			field: "lineNumber",
			title: "Linje",
		},
		{
			//this will not be render
			// field: "lineNumber",
			// title: "Linje",
			cell: (props) => (
				<span style={{ paddingLeft: "10px" }}>{props.dataItem.lineNumber}</span>
			),
		},

		{ field: "product", title: "Produckt" },
		{
			field: "registeredLiveWeightKg",
			title: "Innmeldt",
			cell: (props) => <NumericalCell {...props} unit={"kg"} />,
			headerClassName: CLASSNAME.TableNumeric,
			className: `${CLASSNAME.TableNumeric} no-border`,
			headerCell: (props) => <NumericalHeader {...props} />,
		},
		{
			field: "numberOfPieces",
			title: "Antall",
			cell: (props) => <NumericalCell {...props} unit={""} />,
			headerClassName: CLASSNAME.TableNumeric,
			className: `${CLASSNAME.TableNumeric} no-border`,
			headerCell: (props) => <NumericalHeader {...props} />,
		},
		{
			field: "price",
			title: "Pris",
			width: "120px",
			cell: (props) => <NumericalCell {...props} unit={"kr"} />,
			headerClassName: CLASSNAME.TableNumeric,
			className: `${CLASSNAME.TableNumeric} no-border`,
			headerCell: (props) => <NumericalHeader {...props} />,
		},
		{
			field: "netLiveWeightKg",
			title: "Kvantum",
			width: "120px",

			cell: (props) => <NumericalCell {...props} unit={"kg"} />,
			headerClassName: CLASSNAME.TableNumeric,
			className: `${CLASSNAME.TableNumeric} no-border`,
			headerCell: (props) => <NumericalHeader {...props} />,
		},
		{
			field: "netLiveWeightKg",
			title: "verdi",
			width: "120px",

			cell: (props) => <NumericalCell {...props} unit={"kg"} />,
			headerClassName: CLASSNAME.TableNumeric,
			className: `${CLASSNAME.TableNumeric} no-border`,
			headerCell: (props) => <NumericalHeader {...props} />,
		},
		{
			width: "40px",
			className: `${CLASSNAME.TableNumeric} no-border`,
		},
	];

	return (
		<div
			style={{ marginBottom: "15px", backgroundColor: COLOR.GridOpenedHeader }}
		>
			<Grid
				style={{
					backgroundColor: COLOR.GridOpenedHeader,
					height: " fit-content",
				}}
				rowRender={CustomRowRender}
				rowHeight={30}
				data={data}
				className="custom_detail_grid"
			>
				{gridItems.map((column, key) => (
					<GridColumn key={key} {...column} className="no-border" />
				))}
			</Grid>
		</div>
	);
};

export default DetailedPage;
