import React, { FC, useEffect, useState } from "react";
import { PDFViewer, PDFViewerTool } from "@progress/kendo-react-pdf-viewer";

// import style from "../utils/style.module.css";
import { getPdfApi } from "api/pdf";

const tools: PDFViewerTool[] = [
	"pager",
	"spacer",
	"zoomInOut",
	"zoom",
	"selection",
	"spacer",
	"search",
	//"open",
	"download",
	"print",
];

export const PdfViewer = () => {
	const testData = {
		id: "600000273352",
		type: "11",
	};
	const [pdfData, setPdfData] = useState<string>();

	useEffect(() => {
		getPdfApi(testData.id, testData.type)
			.then((response) => {
				console.log("pdf response", response);
				setPdfData(response);
			})
			.catch((err) => {
				console.log(err);
			});
	}, []);

	return (
		<div style={{ maxHeight: "100%", height: 750 }}>
			<PDFViewer data={pdfData} style={{ height: "100%" }} tools={tools} />
		</div>
	);
};

export default PdfViewer;
