import { FC, useState } from "react";
import CustomButton from "ui/Button";
import { ICON_NAMES } from "utils/constant";
import { useTranslation } from "react-i18next";
import { AnnualStatementFilter } from "api/accounting";
import CustomDropDownList from "ui/dropDown";
import { getVesselFilter } from "api/common";
import { Vessel } from "interface/vessel";

interface CustomProps {
	pageSize: number;
	setFilterOptions: (options: AnnualStatementFilter) => void;
	filterOptions: AnnualStatementFilter;
	exportAsPDF: () => void;
	exportAsExcel: () => void;
}
const Filter: FC<CustomProps> = ({
	setFilterOptions,
	filterOptions,
	exportAsPDF,
	...props
}) => {
	const { t } = useTranslation();

	const [tempFilter, setTempFilter] = useState(filterOptions);

	return (
		<div
			style={{
				display: "flex",
				justifyContent: "space-between",
				flexWrap: "wrap",
			}}
		>
			<div style={{ display: "flex", gap: "10px", marginBottom: "15px" }}>
				<div style={{ width: "170px" }}>
					<CustomDropDownList
						data={[]}
						filterable={true}
						dataItemKey="id"
						textField="nm"
						label={t("Vessel")}
						value={{
							id: filterOptions.vesselId || null,
							nm: filterOptions.vesselName || t("ChooseVessel"),
						}}
						// label="Velg Fartøy"
						defaultItem={{ id: null, nm: t("All") }}
						onSelect={(vessel: Vessel) => {
							setFilterOptions({
								...filterOptions,
								vesselId: vessel.id,
								vesselName: vessel.id ? vessel.nm : "",
								vesselLedgerAccountNumber: vessel.res as any,
								take: props.pageSize,
								skip: 0,
							});
						}}
						serverFilter={true}
						serverFilterApi={getVesselFilter}
						popupSettings={{ width: 400 }}
					/>
				</div>
			</div>

			<div
				style={{
					display: "flex",
					justifyContent: "flex-end",
					flexGrow: 1,
					gap: "15px",
				}}
			>
				<CustomButton
					label={"PDF"}
					onClick={() => exportAsPDF()}
					iconName={ICON_NAMES.Download}
				/>
				<CustomButton
					label={"Excel"}
					onClick={() => props.exportAsExcel()}
					iconName={ICON_NAMES.Download}
				/>
			</div>
		</div>
	);
};

export default Filter;
