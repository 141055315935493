import {
	GridColumnProps,
	GridPageChangeEvent,
} from "@progress/kendo-react-grid";
import { Card, CardBody } from "@progress/kendo-react-layout";
import React, { useState } from "react";
import styles from "../../../styles.module.css";
import style from "./style.module.css";
import { ICON_NAMES } from "utils/constant";
import { sortGrid } from "utils/helper";
import { SortDescriptor } from "@progress/kendo-data-query";
import CustomGrid from "ui/grid/CustomGrid";
import GridCommandCell from "ui/grid/GridCommandCell";
import { TextButton } from "ui/Button";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { PageTypePath } from "utils/pages";

interface Weather {
	date: Date;
	temperatureC: number;
	temperatureF: number;
	summary: string;
}

function AccountingSettlementCard() {
	const [gridData, setGridData] = useState<Weather[]>([]);
	const [weather1, setWeather1] = useState<Weather[]>([]);
	const [sort, setSort] = useState<SortDescriptor[]>(
		sortGrid([{ fieldName: "price" }, { fieldName: "rating" }])
	);
	const [page, setPage] = useState({ skip: 0, take: 10 });

	const pageChange = (event: GridPageChangeEvent) => {
		setPage({
			...event.page,
		});
	};
	const { t } = useTranslation();
	const navigate = useNavigate();

	const gridItems: GridColumnProps[] = [
		{ field: "temperatureC", title: t('Date'), format: "{0:c}" },
		{ field: "temperatureC", title: t('SettlementNumber') },
		{ field: "temperatureC", title: t('Vessel') },
		{ field: "temperatureC", title: t('PrintDate') },
		{ field: "temperatureC", title: t('PrintedBy') },
		{ field: "temperatureC", title: t('Value') },
		{ cell: (props) => <GridCommandCell {...props} /> },
	];

	const handleClick = () => {};

	return (
		<div>
			<Card className={style.CardItem}>
				<CardBody>
					<div className={`${styles.BoxHeadingMedium} ${style.CardBodyHeader}`}>
						<div>{t('LastSettlements')}</div>
						<div>
							<TextButton
								text={t('OverviewSettlements')}
								onClick={() => navigate(`/${PageTypePath.SalesNotesList}`)}
								iconName={ICON_NAMES.Arrow}
							/>
						</div>
					</div>
					<div>
						<CustomGrid gridColumns={gridItems} data={gridData} take={3} />
					</div>
				</CardBody>
			</Card>
		</div>
	);
}

export default AccountingSettlementCard;
