import styles from "styles.module.css";
import { useTranslation } from "react-i18next";

const Error404 = () => {
    const { t } = useTranslation();

    return (
        <div className={styles.PageContainer}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
                <legend
                    className={styles.BoxHeadingLarge}
                    style={{ paddingBottom: "20px" }}
                >
                    Error 404
                </legend>
            </div>
            <div>
                <h1>{t("PageNotFound")}</h1>
            </div>
        </div>
    );
}

export default Error404;