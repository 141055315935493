import React, { useEffect, useState } from "react";
import {
	PanelBar,
	PanelBarItem,
	PanelBarUtils,
	PanelBarSelectEventArguments,
    MenuItem,
} from "@progress/kendo-react-layout";
//import { MenuPageName, PageGroup, PageTypePath } from "utils/pages";
import { PageTypePath } from "utils/pages";

import { getMenuApi } from "api/menu";

import { Font } from "utils/constant";
import { useNavigate, useParams } from "react-router-dom";
import { useAuthStore, useUserStore } from "store";
import { cookieAuthApi } from "api/auth";
import { logoutApi } from "api/auth";

const SideMenuBar: React.FC<{
	currentPage: string;
}> = ({ currentPage }) => {

	const navigate = useNavigate();
	const { user, setStore } = useUserStore();
	const { setLogout } = useAuthStore();

	let { path, id, action } = useParams();

	const [menuData, setMenuData] = React.useState<Array<any>>([]);

	const handleOnselect = (e: PanelBarSelectEventArguments) => {
		const path = e.target.props.path;

		if (path === PageTypePath.Home) {
			navigate(`/`);
			return;
		} else if (path === PageTypePath.Logout) {
			setLogout();
			logoutApi()
				.then(() => {
					navigate(`/login`);
				})
				.catch((error) => {
					console.log(error);
				});
			return;
		}

		if (path) {
			navigate(`/${path}`);
		}
	};

	useEffect(() => {}, [path]);

	useEffect(() => {
		cookieAuthApi()
			.then((response) => {
				setStore(response.roles, response.user);
			})
			.catch((error) => {
				navigate(`/login`);
			});

			getMenu();
	}, []);


	const getMenu = () => {
		getMenuApi()
			.then((response) => {
				try {
					if (path) {
						response.forEach((item) => {
							let expand = false;

							if (item.path === path) {
								item.selected = true;
							}
							else {
								if (item.children) {

									item.children.forEach((child) => {
										if (child.path === path) {
											child.selected = true;
											expand = true;
										}
									});

									if (expand) {
										item.expanded = true;
									}
								}
							}
						});
					}
					else {
						response[0].selected = true;
					}
				} catch (error) {
					console.log('error', error);
				}

				console.log('response', response);

				setMenuData(response);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const components = PanelBarUtils.mapItemsToComponents(menuData);

	if (!user) return <div></div>;
	return (
		<PanelBar
			style={{
				top: "4px",
				right: "0px",
				backgroundColor: "transparent",
				fontFamily: Font.WorkSans,
				fontSize: "15px",
				border: "1px solid transparent",
			}}
			onSelect={handleOnselect}
			expandMode="single"
			keepItemsMounted={true}
			children={components} />
	);
};

export default SideMenuBar;
