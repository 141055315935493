import { COLOR } from "utils/constant";
import { FC } from "react";

const ErrorInfo: FC<{ show: boolean; text: string }> = ({ text, show }) => {
	//Todo: add styls
	return (
		<div style={{ color: COLOR.StatusRed, display: show ? "block" : "none" }}>
			<span>{text}</span>
		</div>
	);
};

export default ErrorInfo;
