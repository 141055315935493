import React, { useEffect, useRef } from 'react';
import { Slider, } from "@progress/kendo-react-inputs";
import { useTranslation } from "react-i18next";

const CatchNotificationCard: React.FC<{ specieType: string, specieCatchWeight: number, combineSpeciesCatchWeight: number, restQuota?: number, sliderColor: string }> = ({ specieType, specieCatchWeight, combineSpeciesCatchWeight, restQuota, sliderColor }) => {
    const { t } = useTranslation();

    const sliderRef = useRef<HTMLElement>();

    let percentValue = parseFloat(((specieCatchWeight / combineSpeciesCatchWeight) * 100).toFixed(2));

    //Todo:  What is the colors based on ?
    //Will they be fixed in respective of the value/percent ? .

    useEffect(() => {
        if (sliderRef.current) {
            const slider = (sliderRef.current as any)?._sliderTrack as
                | HTMLElement
                | undefined;

            if (slider) {
                const track = slider.querySelector(".k-slider-selection") as HTMLElement;
                track.style.backgroundColor = sliderColor; // Set color based on .....
                track.style.height = "7px"
            }
        }
    }, []);

    return (
        <div style={{ padding: "10px" }}>
            <div style={{ display: "flex", justifyContent: "space-between", }}>
                <span> {specieType}</span>
                <span> {percentValue}%</span></div>
            <div>
                <Slider min={0} max={100} value={percentValue} ref={sliderRef} />
            </div>
            <div>
                <div>
                    <span>{t('MyCatch')}</span>
                    <span> {specieCatchWeight}</span>
                </div>
                <div>
                    <span>{t('RestQuota')}</span>
                    <span> {restQuota}</span>
                </div>

            </div>
        </div>
    );
}

export default CatchNotificationCard;