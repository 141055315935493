import { Card, CardBody } from "@progress/kendo-react-layout";
import React from "react";
import { COLOR } from "../../../utils/constant";
import CatchCard from "./CatchDataCard";
import Notification from "./Notification";
import styles from "../../../styles.module.css";

function RightItems({ title, iconName }: { title: string; iconName?: string }) {
	return (
		<div
			style={{
				display: "flex",
				flexDirection: "column",
				width: "360px",
				borderRadius: "18px",
				gap: "30px",
			}}
		>
			<Card
				style={{
					borderRadius: "18px",
					flex: 1,
					paddingLeft: "10px",
					paddingRight: "10px",
					paddingTop: "10px",
				}}
			>
				<CardBody>
					<div
						className={styles.BoxHeadingMedium}
						style={{ display: "flex", gap: "5px" }}
					>
						{iconName && <img src={`/icon/${iconName}.svg`} alt={iconName} />}

						<span>{title}</span>
					</div>
					<CatchCard
						specieType={"Torsk"}
						specieCatchWeight={800}
						restQuota={700}
						combineSpeciesCatchWeight={1500}
						sliderColor={COLOR.BlueLight}
					/>
					<CatchCard
						specieType={"Sei"}
						specieCatchWeight={400}
						restQuota={1100}
						combineSpeciesCatchWeight={1500}
						sliderColor={COLOR.RedLight}
					/>
					<CatchCard
						specieType={"Torsk"}
						specieCatchWeight={300}
						restQuota={1200}
						combineSpeciesCatchWeight={1500}
						sliderColor={COLOR.YellowLight}
					/>
				</CardBody>
			</Card>

			<Card
				style={{
					borderRadius: "18px",
					flex: 1,
					height: "100%",
					paddingLeft: "10px",
					paddingRight: "10px",
					paddingTop: "20px",
				}}
			>
				<CardBody>
					<Notification />
				</CardBody>
			</Card>
		</div>
	);
}

export default RightItems;
