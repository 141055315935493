import React, { useRef, useState } from "react";
import Filter from "./Filter";
import GridCustomPage from "ui/grid/GridPage";
import { getInvoicesApi, InvoiceFilter } from "api/accounting";
import styles from "styles.module.css";
import { t } from "i18next";
import { GridPDFExport } from "@progress/kendo-react-pdf";
import { ExcelExport } from "@progress/kendo-react-excel-export";
import { InvoiceGridColumnItem } from "./GridColumns";
import { GridCellProps, GridRowProps } from "@progress/kendo-react-grid";
import { PageTypePath } from "utils/pages";
import { PdfType } from "utils/PdfType";
import { ICON_NAMES } from "utils/constant";
import InvoiceHeader from "./InvoiceHeader";

const pageSize = 60;

function Invoice() {
	const gridRef = useRef<HTMLDivElement>(null);
	const pdfRef = useRef<GridPDFExport>(null);
	const excelRef = useRef<ExcelExport>(null);
	const [filterOptions, setFilterOptions] = useState<InvoiceFilter>({
		take: pageSize,
		skip: 0,
		fromDate: null,
		toDate: null,
	});

	const exportGridAsPDF = () => {
		if (pdfRef.current !== null) {
			pdfRef.current.save();
		}
	};

	const exportGridAsExcel = () => {
		if (excelRef.current !== null) {
			excelRef.current.save();
		}
	};

	const openPdfByRow = (prop: GridCellProps) => {
		window.open(
			`/${PageTypePath.Pdf}/${prop.dataItem.id}/${PdfType.Invoice}`,
			"_blank"
		);
	};
	return (
		<div className={styles.PageContainer}>
			<div style={{ display: "flex", justifyContent: "space-between" }}>
				<legend
					className={styles.BoxHeadingLarge}
					style={{ paddingBottom: "20px" }}
				>
					{t("Invoice")}
				</legend>
			</div>
			<div>
				<InvoiceHeader />
			</div>
			<div>
				<Filter
					setFilterOptions={setFilterOptions}
					filterOptions={filterOptions}
					exportAsPDF={exportGridAsPDF}
					exportAsExcel={exportGridAsExcel}
					pageSize={pageSize}
				/>
			</div>
			<div style={{ marginTop: 0 }} ref={gridRef}>
				<GridCustomPage
					dataKey="id"
					gridColumns={InvoiceGridColumnItem()}
					gridHeight={600}
					// detailPage={DetailedPage}
					handelShowRowBgColor={handelShowRowBgColor}
					filter={filterOptions}
					exportGridAsPDFRef={pdfRef}
					exportGridAsExcelRef={excelRef}
					lastColWidth="60px"
					apiMethod={getInvoicesApi}
					showDetailIcon={false}
					lastColBtns={[
						{
							onclick: openPdfByRow,
							iconName: ICON_NAMES.Download,
							alwaysShow: true,
						},
					]}
				/>
			</div>
		</div>
	);
}

export default Invoice;

const handelShowRowBgColor = (rowData: GridRowProps) => {
	const data = rowData.dataItem;
	if (data.invoiceTypeId === 5) {
		if (data.netValue < 0) {
			return "rgb(166 36 8 / 10%)";
		} else {
			return "rgb(8 166 87 / 10%)";
		}
	}
	return "";
};
