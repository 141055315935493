 export interface User  {
    id: string
}

export interface Auth {
    isLogin: boolean;
    isVerified: boolean,
    twoFactorVerified: boolean
    email: string
}

export enum UserRole {
    FartoyKjoperLandingsted = "Fartøy/Kjøper/Landingsted",
    WebserviceUser = "WebserviceUser",
    Admin = "Admin",
    Leppefiskere = "Leppefiskere",
    RederFartoyeier = "Reder-fartøyeier",
    Kystfisk = "Kystfisk",
    FartoyKjoper = "Fartøy/Kjøper",
    Fartoy = "Fartøy",
    Fiskeridirektoratet = "Fiskeridirektoratet",
    PassiveKjopere = "PassiveKjøpere",
    Kystvakt = "Kystvakt",
    Kjoper = "Kjøper",
    AndreSalgslag = "AndreSalgslag",
    Landingssted = "Landingssted",
    Mattilsynet = "Mattilsynet",
    SUROFI = "SUROFI",
    PassiveBrukere = "PassiveBrukere",
    KystfiskerKjoper = "Kystfisker/Kjøper",
    KjoperLandingsted = "Kjøper/Landingsted"
}



export interface AuthenticationResponse {
    user: User
    roles: UserRole[]
}

export enum OrganizationType{
    Buyer = "Buyer",
    Vessel = "Vessel",
    Terminal = "Terminal"
  }
  
 export  interface UserAccessOrganization{
     businessNumber: string
     ledgerAccountNumber?: string
     displayName: string
     buyerName: string
     terminalName: string
     id?: number
     type: OrganizationType
  }

