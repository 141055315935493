import { Checkbox } from "@progress/kendo-react-inputs";
import React, { useState } from "react";
import { forgotPasswordApi } from "api/auth";
import CustomButton from "ui/Button";
import { CustomInput } from "ui/Input";
import { COLOR, ICON_NAMES } from "utils/constant";
import "./style.css";
import ErrorInfo from "ui/ErrorInfo";
import { useNavigate } from "react-router";
import CustomText from "ui/Text";

function ForgotPassword() {
	const [email, setEmail] = useState();
	const [errorMessage, setErrorMessage] = useState("");
	const [error, setError] = useState(false);
	const [emailSent, setEmailSent] = useState(false);

	//Todo: How should we handle password reset ? ( through email url or  optcode  and redirect new password page)

	const navigate = useNavigate();

	const submit = () => {
		resetEroor();
		if (!email) {
			setError(true);
			setErrorMessage("Email is required");
			return;
		}

		forgotPasswordApi(email)
			.then((response) => {
				setEmailSent(true);
			})
			.catch((error) => {
				setErrorMessage("Something went wrong");
			});
	};

	const resetEroor = () => {
		setError(false);
		setErrorMessage("");
	};
	return (
		<div
			style={{
				height: "100vh",
				backgroundColor: "#004959",
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
			}}
		>
			<div
				style={{
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
					width: "310px",
				}}
			>
				<div
					style={{
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
						marginBottom: "60px",
					}}
				>
					<img
						src={`/icon/${ICON_NAMES.SurofiTransparent}.svg`}
						alt="surofi"
						style={{ marginBottom: "10px" }}
					/>
					<span style={{ color: "white" }}>MinSide</span>
				</div>

				<div
					style={{
						display: "flex",
						flexDirection: "column",
						width: "100%",
					}}
				>
					<div style={{ color: COLOR.Grey98, margin: 10 }}>
						<CustomText
							text=" Request new password message , add the message for the user to
              request new password"
						/>
					</div>
					<CustomInput
						onChange={(email) => setEmail(email)}
						value={email}
						placeholder="Email  "
						style={{ marginBottom: "10px" }}
						type="email"
						className={error ? "input-container error" : "input-container"}
					/>
				</div>

				<ErrorInfo show={error} text={errorMessage} />
				{emailSent && (
					<div>
						If account with this email exist, an email has been sent to reset
						your password
					</div>
				)}

				<div className="button-containter">
					<CustomButton
						label={"Send"}
						bgColor="#006980"
						color={COLOR.Grey98}
						onClick={submit}
					/>

					<CustomButton
						label={"Logg inn?"}
						bgColor="#004959"
						color={COLOR.Grey98}
						onClick={() => navigate("/login")}
					/>
				</div>
			</div>
		</div>
	);
}

export default ForgotPassword;
