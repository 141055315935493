import React from "react";
import ItemCard from "ui/Card";
import RegistrationCard from "./RegistrationCard";
import SalesNoteCard from "./SalesNoteCard";
import AccountingSettlementCard from "./AccountingSettelmentCard";
import RightItems from "./RightItems";
import { ICON_NAMES } from "utils/constant";
import { useTranslation } from "react-i18next";
import { useUserOrganizationsStore, useUserStore } from "store";
import LandingNoteCard from "./LandingNoteCard";
import { OrganizationType } from "interface/user";

const HomePageItems = () => {
	const { t } = useTranslation();

	const { user } = useUserStore();

	let year = new Date().getFullYear();
	const { defaultOrg } = useUserOrganizationsStore();

	if (!user || !defaultOrg) return <div></div>;
	return (
		<div style={{ display: "flex", gap: 30, height: 700, maxHeight: 600 }}>
			<div style={{ width: "100%" }}>
				<div
					style={{
						display: "flex",
						gap: "20px",
						justifyContent: "space-between",
						marginBottom: "40px",
						width: "100%",
					}}
				>
					<ItemCard
						title={t("RegisteredSoFarIn") + " " + year}
						subTitle={t("ComparedToLastYear")}
						currentValue={0}
						valueUnit={"Kg"}
						changePercent={"1"}
						isPercentDecrease={false}
					/>
					<ItemCard
						title={t("RegisteredSoFarIn") + " " + year}
						subTitle={t("ComparedToLastYear")}
						currentValue={0}
						valueUnit={"Kg"}
						changePercent={"1"}
						isPercentDecrease={true}
					/>
					<ItemCard
						title={t("RegisteredSoFarIn") + " " + year}
						subTitle={t("ComparedToLastYear")}
						currentValue={0}
						valueUnit={"Kg"}
						changePercent={"1"}
						isPercentDecrease={true}
					/>
				</div>

				<div
					style={{
						display: "flex",
						flexDirection: "column",
						gap: "30px",
						height: "800px",
						minHeight: "100%",
						overflow: "auto",
					}}
				>
					{defaultOrg.type === OrganizationType.Buyer && (
						<>
							<div>
								<RegistrationCard />
							</div>
							<div>
								<SalesNoteCard />
							</div>
							<div>
								<AccountingSettlementCard />
							</div>
						</>
					)}
					{defaultOrg.type === OrganizationType.Vessel && (
						<>
							<div>
								<SalesNoteCard />
							</div>
							<div>
								<LandingNoteCard />
							</div>
							<div>
								<AccountingSettlementCard />
							</div>
						</>
					)}
					{defaultOrg.type === OrganizationType.Terminal && (
						<>
							<div>
								<LandingNoteCard />
							</div>
						</>
					)}
				</div>
			</div>
			<div>
				<RightItems
					iconName={ICON_NAMES.FinishTransparent}
					title={t("MyCatch") + " " + year}
				/>
			</div>
		</div>
	);
};

export default HomePageItems;
