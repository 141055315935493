import {
	GridColumnProps,
	GridCustomCellProps,
	GridHeaderCellProps,
} from "@progress/kendo-react-grid";
import { CLASSNAME } from "utils/constant";
import { GrideDateFormatter } from "ui/Date";
import { FC } from "react";
import i18n from "../../../i18n";
import DotCell from "ui/grid/DotCell";

let t = i18n.t;

export const gridColumnItemsHandler = () => {
	const gridItems: GridColumnProps[] = [
		{
			//This wont show up
			field: "registrationDate",
			title: t("Date"),
		},
		{
			field: "",
			title: "",
			width: "30px",
			headerClassName: "no-border",
			cell: (props) => <DotCell value={props.dataItem.source} />,
		},

		{
			field: "noteNumber",
			title: t("Note"),
			width: "110px",
			headerClassName: "no-border",
		},
		// {
		// 	field: "species",
		// 	title: "Product",
		// 	width: "110px",
		// 	headerClassName: "no-border",
		// },

		{ field: "vesselName", title: t("Vessel") },
		{ field: "fishingGear", title: t("FishingGear") },
		{
			field: "landingTime",
			title: t("DeliveryDateShort"),
			width: "110px",
			headerClassName: "no-border",
			cell: (props) => (
				<GrideDateFormatter
					date={props.dataItem.landingTime}
					formatType={"dd.MM.yyyy"}
				/>
			),
		},
		{
			field: "catchField",
			title: t("CatchArea"),
			width: "100px",
			headerClassName: "no-border",
		},
		{
			field: "catchAreaZone",
			title: t("Zone"),
			width: "80px",
			headerClassName: "no-border",
		},
		{
			field: "netLiveWeightKg",
			title: t("TotalWeight"),
			width: "120px",

			cell: (props) => <NumericalCell {...props} unit={"kg"} />,
			headerClassName: CLASSNAME.TableNumeric,
			className: `${CLASSNAME.TableNumeric} no-border`,
			headerCell: (props) => <NumericalHeader {...props} />,
		},
	];
	return gridItems;
};

export const NumericalHeader: FC<GridHeaderCellProps> = (props) => {
	return <span>{props.title}</span>;
};

export const NumericalCell: FC<GridCustomCellProps & { unit: string }> = ({
	field,
	dataItem,
	unit,
}) => {
	let value;
	if (field) {
		value = dataItem[field];
	}
	// let color = COLOR.TextLight;
	// if (dataItem.status === 0) {
	//   color = COLOR.Grey20;
	// }
	return (
		<td style={{ textAlign: "end", border: "none" }}>
			<span>{value}</span> {unit && <span>{unit}</span>}
		</td>
	);
};
