import { ApiResponse } from "interface/response"
import { AxiosApi } from "./index";
import { UserAccessOrganization } from "interface/user";



// export async function getUserAccessBuyersApi() {
//     const apiUri = "User/AccessBuyers"

//     try {
//         const registrations = await AxiosApi.get<ApiResponse<UserAccessItem[]>>(apiUri, )

//         return registrations.data
//     } catch (error) {
//         throw error
//     }
// }


export async function getUserOrganizationsApi() {
    const apiUri = "User/Organizations"

    try {
        const registrations = await AxiosApi.get<ApiResponse<UserAccessOrganization[]>>(apiUri, )

        return registrations.data
    } catch (error) {
        throw error
    }
}

export async function getUserDefaultOrganizationApi() {
    const apiUri = "User/DefaultOrganization"

    try {
        const registrations = await AxiosApi.get<ApiResponse<UserAccessOrganization>>(apiUri, )

        return registrations.data
    } catch (error) {
        throw error
    }
}

export async function saveUserDefaultOrganizationApi(org:UserAccessOrganization ) {
    const apiUri = "User/SaveDefaultOrganization"

    try {
        const registrations = await AxiosApi.post<ApiResponse<any>>(apiUri,org )

        return registrations.data
    } catch (error) {
        throw error
    }
}

