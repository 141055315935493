import { Form, FormElement, FormRenderProps } from "@progress/kendo-react-form";
import React, { useEffect, useState } from "react";
import styles from "styles.module.css";
import CustomButton from "ui/Button";
import { COLOR, ICON_NAMES } from "utils/constant";
import CustomStepper from "ui/CustomStepper";
import { IRegistrationForm } from "interface/registration";
import FormStepOne from "./form/stepOne";
import FormStepTwo from "./form/stepTwo";
import {
	completeRegistrationApi,
	deleteRegistrationApi,
	registrationEditingDataApi,
} from "api/registration";
import { useNavigate, useParams } from "react-router";
import { PageTypePath } from "utils/pages";
import Info from "ui/Info";
import { useTranslation } from "react-i18next";

import OverLayLoading from "ui/Loading";
const maxStep = 1;

function RegistrationForm() {
	const { t } = useTranslation();

	const [showRequirement, setShowRequirement] = useState(false);
	const [meetRequirement, setMeetRequirement] = useState(false);
	const [infoData, setInfoData] = useState({
		title: "",
		show: false,
		description: "",
		actions: [{}],
	});
	const [form, setForm] = useState<IRegistrationForm>();
	const router = useNavigate();
	const { id, action } = useParams();
	const [loading, setLoading] = useState(true);
	const stepsItems = [
		{ label: t("CatchInformation"), optional: false },
		{ label: t("ProductLines"), optional: false },
	];
	const [step, setStep] = useState(0);

	const onSubmit = () => {};
	const handleDelete = () => {
		if (!form?.id) {
			return;
		}
		deleteRegistrationApi(form?.id)
			.then((response) => {
				router(`/${PageTypePath.RegistrationList}`);
			})
			.catch((error) => {
				setInfoData({
					show: true,
					title: "Slett Error",
					description: "En feil oppstod",
					actions: [
						{ text: "Avbryt", btn: resetInfoData },

						{
							text: "Ok",
							btn: resetInfoData,
						},
					],
				});
			});
	};

	const handleFinish = () => {
		completeRegistrationApi(id as any)
			.then((data) => {
				router(`/${PageTypePath.RegistrationList}`);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const handleNext = () => {
		if (!meetRequirement) {
			setShowRequirement(true);
			return;
		}
		if (form?.terminalId) {
			setStep(maxStep);
		} else {
			setStep(step + 1);
		}
		setShowRequirement(false);
	};

	useEffect(() => {
		if (!action) {
			return;
		}
		const _step = parseInt(action);
		if (!Number.isNaN(_step) && _step <= maxStep && _step !== 0) {
			setStep(_step);
		}
	}, []);

	useEffect(() => {
		if (form || !id) {
			setLoading(false);
			return;
		}
		registrationEditingDataApi(id as any)
			.then((response) => {
				if (response.data.lines) {
					response.data.lines = response.data.lines.map((line) => ({
						...line,
						inEdit: true,
					}));
				}

				setForm(response.data);
			})
			.catch((err) => {
				console.log(err);
			})
			.finally(() => {
				setLoading(false);
			});
	}, []);

	const confirmDelete = () => {
		setInfoData({
			show: true,
			title: "Slett innmelding",
			description: "Er du sikker du vil slette innmelding",
			actions: [
				{ text: "Avbryt", btn: resetInfoData },

				{
					text: "Ok",
					btn: handleDelete,
					bgColor: "red",
					textColor: "white",
				},
			],
		});
	};
	const resetInfoData = () => {
		setInfoData({
			show: false,
			title: "",
			description: "",
			actions: [],
		});
	};
	if (loading && !form) return <OverLayLoading />;

	return (
		<div className={styles.PageContainer} style={{ height: "100%" }}>
			<legend
				className={styles.BoxHeadingLarge}
				style={{ paddingBottom: "20px" }}
			>
				{t("NewRegistration")}
			</legend>
			<CustomStepper
				steps={stepsItems}
				activeStep={step}
				setActiveStep={setStep}
			/>
			<div
				style={{
					display: "flex",
					flexDirection: "column",
					height: "100%",
					justifyContent: "space-between",
				}}
			>
				<div style={{ height: "526px" }}>
					<Form
						onSubmit={onSubmit}
						render={(formRenderProps: FormRenderProps) => (
							<FormElement style={{ width: "100%" }}>
								<fieldset className={"k-form-fieldset"}>
									{step === 0 && (
										<FormStepOne
											form={form as any}
											setStep={setStep}
											setForm={setForm}
											showRequirement={showRequirement}
											setMeetRequirement={setMeetRequirement}
										/>
									)}
									{step === 1 && (
										<FormStepTwo
											form={form as any}
											setForm={setForm}
											showRequirement={showRequirement}
											setMeetRequirement={setMeetRequirement}
										/>
									)}
								</fieldset>
							</FormElement>
						)}
					/>
				</div>

				<div>
					<div
						style={{
							display: "flex",
							justifyContent: "space-between",
							flex: 1,
						}}
					>
						<div style={{ display: "flex", gap: "10px" }}>
							<CustomButton
								onClick={confirmDelete}
								label={t("DeleteTemporaryData")}
								iconName={ICON_NAMES.Delete}
							/>

							<span style={{ paddingTop: "7px" }} className={styles.TableText}>
								{t("AllChangesSaved")}
							</span>
							<span style={{ paddingTop: "7px" }}>
								<img
									src={`/icon/checkmark_green.svg`}
									alt={"complete icon"}
									style={{ color: "red" }}
								/>
							</span>
						</div>

						<div style={{ display: "flex", gap: "10px" }}>
							{step > 0 && (
								<CustomButton
									onClick={() => setStep(step - 1)}
									label={t("Previous")}
									iconName={ICON_NAMES.Prevous}
									iconRight={false}
								/>
							)}

							{(step === maxStep || form?.noteType === 1) && (
								<>
									<CustomButton
										onClick={handleFinish}
										label={t("Complete")}
										bgColor={COLOR.Green}
										color={"white"}
										iconName={ICON_NAMES.FinishTransparent}
									/>
								</>
							)}

							{step < maxStep && form?.noteType === 0 && (
								<CustomButton
									onClick={handleNext}
									label={t("Next")}
									bgColor={COLOR.Green}
									color={"white"}
									iconName={ICON_NAMES.NextWhite}
								/>
							)}
						</div>
					</div>
					<Info
						close={resetInfoData}
						title={infoData.title}
						show={infoData.show}
						description={infoData.description}
						actions={infoData.actions as any}
					/>
				</div>
			</div>
		</div>
	);
}

export default RegistrationForm;
