import React from 'react'
import { Loader } from "@progress/kendo-react-indicators";
const types = ["pulsing", "infinite-spinner", "converging-spinner"];

export default function OverLayLoading() {
  return (
    <div className='overlay'>
      <Loader size="large" type={"pulsing"}  />
    </div>
  );
}
