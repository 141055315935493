import { Checkbox } from "@progress/kendo-react-inputs";
import React, { useEffect, useState } from "react";
import {
	requestTwoFactorAuthenticationCodeApi,
	twoFactorAuthenticationApi,
	userVerificationApi,
} from "api/auth";
import { useAuthStore } from "store/auth";
import CustomButton from "ui/Button";
import { CustomInput } from "ui/Input";
import { COLOR, ICON_NAMES } from "utils/constant";
import "./style.css";
import ErrorInfo from "ui/ErrorInfo";
import { useLocation, useNavigate, useParams } from "react-router";
import Info from "ui/Info";
import { VerificationType } from "interface/common";
import AuthenticationSuccess from "./Success";
import { t } from "i18next";

enum ErrorType {
	UserIdMissing = "UserIdMissing",
	TokenMissing = "TokenMissing",
	CredentialMissing = "CredentialMissing",
	TokenExpired = "TokenExpired",
	TokenNotValid = "TokenNotValid",
	Other = "other",
}

function OptVerification() {
	const [form, setForm] = useState({ optCode: "", rememberDevice: false });
	const [errorMessage, setErrorMessage] = useState<{
		title?: string;
		description: string;
		actions?: {}[];
	}>();
	const [loading, setLoading] = useState(true);
	const [isVerified, setIsVerified] = useState(false);
	const [errorType, setErrorType] = useState<ErrorType>();
	const [error, setError] = useState(false);
	const { email } = useAuthStore();

	const navigate = useNavigate();
	const location = useLocation();
	const { type } = useParams();

	useEffect(() => {
		const queryParams = new URLSearchParams(location.search);
		const userId = queryParams.get("userId");
		if (!userId) {
			return;
		}
		if (type === VerificationType.Email) {
			const token = queryParams.get("token");
			if (!token) {
				setErrorType(ErrorType.TokenMissing);
				return;
			}
			verification(userId, token, VerificationType.Email);
		} else if (type === VerificationType.Phone) {
		}
	}, []);

	const submit = () => {
		if (!form.optCode) {
			setError(true);
			setErrorMessage({ description: "Engangskode is required" });
			return;
		}

		if (error) {
			setError(false);
			setErrorMessage(undefined);
		}

		twoFactorAuthenticationApi(email, form.optCode)
			.then((response) => {
				console.log(response);
				//set user
			})
			.catch((error) => {
				setErrorMessage({
					description: "Engangskode  is invalid or expired, try again!",
				});
				console.log(error);
			});
	};

	const request2fa = () => {
		if (!email) {
			setErrorMessage({ description: "Something went wrong" });
			return;
		}
		resetEroor();
		requestTwoFactorAuthenticationCodeApi(email)
			.then((reponse) => {})
			.catch((error) => {
				setErrorMessage({ description: "Something went wrong" });
			});
	};
	const resetEroor = () => {
		setError(false);
		setErrorMessage(undefined);
	};
	const verification = (
		userId: string,
		token: string,
		verificationType: VerificationType
	) => {
		userVerificationApi({ userId, token, verificationType })
			.then((response) => {
				console.log(response);
				setIsVerified(true);
			})
			.catch((error) => {
				if (error.status === 404) {
					setErrorType(ErrorType.Other);

					setErrorMessage({
						title: "Verification error",
						description:
							"Something went wrong in the verification,  try again!",
						actions: [
							{
								text: "Ok",
								btn: () => navigate("/login"),
							},
							{
								text: "Try again",
								btn: () => navigate("/login"),
							},
						],
					});
					return;
				}
				if (type === VerificationType.Email) {
					setErrorMessage({
						title: "Invalid details",
						description:
							"Verification token has expired or not valid,  try again",
						actions: [
							{
								text: "Ok",
								btn: () => navigate("/login"),
							},
							{
								text: "Try again",
								btn: () => navigate("/login"),
							},
						],
					});
					setErrorType(ErrorType.TokenExpired);

					return;
				}
				if (type === VerificationType.Phone) {
					setErrorType(ErrorType.TokenExpired);

					setErrorMessage({
						title: "Invalid details",
						description: "OPT token has expired or not valid,  try again",
						actions: [
							{
								text: "New  code",
								btn: request2fa,
							},
							{
								text: "Go to login",
								btn: () => navigate("/login"),
							},
						],
					});
					return;
				}

				console.log(error);
			})
			.finally(() => {
				setLoading(false);
			});
	};
	return (
		<div
			style={{
				height: "100vh",
				backgroundColor: "#004959",
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
			}}
		>
			{isVerified ? (
				<AuthenticationSuccess
					title={"Verification successful"}
					description={"You account has verified successfully"}
					actions={[
						{
							text: t("Login"),
							btn: () => navigate("/login"),
						},
					]}
				/>
			) : (
				<div
					style={{
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
						width: "310px",
					}}
				>
					<div
						style={{
							display: "flex",
							flexDirection: "column",
							alignItems: "center",
							marginBottom: "60px",
						}}
					>
						<img
							src={`/icon/${ICON_NAMES.SurofiTransparent}.svg`}
							alt="surofi"
							style={{ marginBottom: "10px" }}
						/>
						<span style={{ color: "white" }}>MinSide</span>
					</div>

					<div
						style={{
							display: "flex",
							flexDirection: "column",
							width: "100%",
						}}
					>
						<CustomInput
							onChange={(optCode) => setForm({ ...form, optCode })}
							value={form.optCode}
							placeholder="Engangskode fra SMS"
							style={{ marginBottom: "10px" }}
							type="number"
							className={error ? "input-container error" : "input-container"}
						/>

						<Checkbox
							value={form.rememberDevice}
							label={"Husk denne enheten i 14 dager"}
							onChange={() =>
								setForm({ ...form, rememberDevice: !form.rememberDevice })
							}
						/>
					</div>

					<ErrorInfo show={error} text={errorMessage?.description || ""} />

					<div className="button-containter">
						<CustomButton
							label={"Bekreft kode"}
							bgColor="#006980"
							color={COLOR.Grey98}
							onClick={submit}
						/>

						<CustomButton
							label={"Send ny kode?"}
							bgColor="#004959"
							color={COLOR.Grey98}
							onClick={request2fa}
						/>
					</div>
					<Info
						close={() => setErrorType(undefined)}
						title={errorMessage?.title as any}
						show={errorType ? true : false}
						description={errorMessage?.description as any}
						actions={errorMessage?.actions as any}
					/>
				</div>
			)}
		</div>
	);
}

export default OptVerification;
