import React from 'react';
import './App.css';
import {

    BrowserRouter,
} from 'react-router-dom';
import CustomRouter from './router';

function App() {

    return (
       <BrowserRouter>
        <CustomRouter />
    </BrowserRouter>
      

    ) 

}

export default App;
