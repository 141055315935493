import {
	PanelBar,
	PanelBarItem,
	PanelBarSelectEventArguments,
} from "@progress/kendo-react-layout";
import React, { FC, useEffect, useRef } from "react";
import { ICON_NAMES } from "../utils/constant";
import { Popup } from "@progress/kendo-react-popup";
import { useClickOutside, useEscapeKey } from "utils/hooks";
import { useAuthStore } from "store";
import { logoutApi } from "api/auth";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import { publish } from "../utils/events";
//import i18n from "../i18n";

enum Settings {
	Language = "English",
	Logout = "Logg out",
	User = "Min bruker",
}
export function UserSetting() {
	const { t, i18n } = useTranslation();

	const { setLogout } = useAuthStore();
	const navigate = useNavigate();

	const language = i18n.language;

	const logout = () => {
		setLogout();
		logoutApi()
			.then(() => {
				navigate(`/login`);
			})
			.catch((error) => {
				console.log(error);
			});
	};

	const handleSelect = (e: PanelBarSelectEventArguments) => {
		const name = e.target.props.name;
		if (name === Settings.Language) {
			//Todo: add switch language
			i18n.changeLanguage(i18n.language === "no" ? "en" : "no");
			window.location.reload();
		} else if (name === Settings.Logout) {
			logout();
		} else if (name === Settings.User) {
			//Todo: add user setting page
		}
	};
	return (
		<PanelBar
			style={{
				right: "0px",
				backgroundColor: "transparent",
				borderRadius: "12px",
				fontSize: "15px",
			}}
			onSelect={handleSelect}
			expandMode={"single"}
		>
			<PanelBarItem
				title={t("MyUser")}
				imageUrl={`/icon/${ICON_NAMES.UserAccount}.svg`}
				name={Settings.User}
			/>
			{/* <PanelBarItem
				title={"Innstillinger"}
				imageUrl={`/icon/${ICON_NAMES.SettingsGreen}.svg`}
			/> */}
			<PanelBarItem
				title={i18n.language === "no" ? t("English") : t("Norwegian")}
				imageUrl={`/icon/${ICON_NAMES.Language}.svg`}
				name={Settings.Language}
			/>
			<PanelBarItem
				title={t("Logout")}
				imageUrl={`/icon/logout.svg`}
				name={Settings.Logout}
			/>
		</PanelBar>
	);
}

const AccountSettingPopup: FC<{
	show: boolean;
	setShow: (show: boolean) => void;
	anchorRef: HTMLDivElement;
}> = ({ show, setShow, anchorRef }) => {
	const popupRef = useRef<any>(null);

	useEscapeKey(() => {
		if (show) {
			setShow(!show);
		}
	});

	useClickOutside(popupRef, (node) => {
		if (show) {
			const classes = [
				"k-panelbar",
				"k-panelbar-item-text",
				"k-panelbar-item",
				"k-panelbar-header",
				"k-panelbar-item-icon",
				"k-link",
			];
			if (node.classList.contains("account-icon")) {
				return;
			}
			const hasClass = classes.some((className) =>
				node.classList.contains(className)
			);
			if (!hasClass) {
				setShow(false);
			}
		}
	});

	return (
		<div ref={popupRef} style={{ borderRadius: "12px" }}>
			<Popup
				onClose={() => {}}
				anchor={anchorRef}
				anchorAlign={{
					horizontal: "right",
					vertical: "top",
				}}
				popupAlign={{
					horizontal: "right",
					vertical: "bottom",
				}}
				offset={{
					left: 1000,
					top: 0,
				}}
				show={show}
				style={{
					marginTop: "15px",
					borderRadius: "12px !important",
					background: "none",
				}}
				popupClass={"user-setting-popup"}
			>
				<UserSetting />
			</Popup>
		</div>
	);
};

export default AccountSettingPopup;
