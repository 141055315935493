import { GridCustomFooterCellProps } from "@progress/kendo-react-grid";
import React, { FC } from "react";
import { COLOR } from "../../utils/constant";
import styles from "../../styles.module.css";
import { NumericFormat } from "react-number-format";

//export const FooterAggregateSum: FC<{
//	skip?: number;
//	take?: number;
//	field?: string;
//	data: any[];
//	props: GridCustomFooterCellProps;
//	text?: string;
//	paddingRight?: number;
//	unit?: string;
//}> = ({
//	props,
//	skip = 0,
//	data,
//	take = data.length,
//	field,
//	text,
//	unit,
//	paddingRight = 0,
//}) => {
//	let sum = 0;
//	data.slice(skip, take).forEach((item) => {
//		if (field && item[field]) {
//			const value = parseInt(item[field]);
//			if (!isNaN(value)) {
//				sum = sum + value;
//			}
//		}
//	});

//	return (
//		<td
//			className={`${styles.InputLabel} no-border`}
//			colSpan={props.colSpan}
//			style={{
//				...props.style,
//				textAlign: "end",
//				color: COLOR.TextLight,
//				// paddingRight: paddingRight,
//			}}
//		>
//			{text && unit ? (
//				<>
//					{text}: {sum} {unit}
//				</>
//			) : (
//				<>
//					{text && `${text}: `}
//					{sum}
//					{unit && ` ${unit}`}
//				</>
//			)}
//		</td>
//	);
//};

export const FooterAggregateSum: FC<{
	skip?: number;
	take?: number;
	field?: string;
	data: any[];
	props: GridCustomFooterCellProps;
	text?: string;
	paddingRight?: number;
	unit?: string;
	decimalScale?: number;
	fixedDecimalScale?: boolean;
}> = ({
	props,
	skip = 0,
	data,
	take = data.length,
	field,
	text,
	unit,
	decimalScale = 0,
	fixedDecimalScale = false,
	paddingRight = 33,
}) => {
	let sum = 0;

	data.slice(skip, take).forEach((item) => {
		if (field && item[field]) {
			const value = parseFloat(item[field]);
			if (!isNaN(value)) {
				sum = sum + value;
			}
		}
	});

	return (
		<td
			className={`${styles.InputLabel} no-border`}
			colSpan={props.colSpan}
			style={{
				...props.style,
				textAlign: "end",
				color: COLOR.TextLight,
				//paddingRight: paddingRight,
			}}
		>
			<NumericFormat value={sum} displayType={'text'} decimalScale={decimalScale} fixedDecimalScale={fixedDecimalScale} thousandSeparator=" " decimalSeparator="," />
			{unit && <span>{unit}</span>}
		</td>
	);
};
