import { AxiosApi } from "./index";



export const   sendToSign =  async ({buyerSigner, vesselSigner, apprenticeSigner, documentNumber }: {buyerSigner: string, vesselSigner: string, apprenticeSigner?: string, documentNumber: string })   => {
    const apiUri = "CatchCertificationSigning/SendToSign"

    try {
        const registrations = await AxiosApi.get<string>(apiUri, {
            params: {
                receiver: buyerSigner, vessel: vesselSigner, apprentice: apprenticeSigner,
                no: documentNumber
            }
        })

        return registrations.data
    } catch (error) {
        throw error
    }
}

