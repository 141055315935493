import {
	GridCellProps,
	GridColumnProps,
	GridCustomCellProps,
	GridHeaderCellProps,
} from "@progress/kendo-react-grid";
import { CLASSNAME, COLOR, ICON_NAMES } from "utils/constant";
import { GrideDateFormatter } from "ui/Date";
import { FC } from "react";
//import styles from "styles.module.css";
import i18n from "../../../../i18n";
import { Skeleton } from "@progress/kendo-react-indicators";
import DotCell from "ui/grid/DotCell";

let t = i18n.t;

interface Param {
	editItem: (props: GridCellProps) => void;
	openDetails: (props: GridCellProps) => void;
}

export const gridColumnItemsHandler = () => {
	const gridItems: GridColumnProps[] = [
		{
			//This wont show up
			field: "registrationDate",
			title: "Dato",
		},
		{
			field: "",
			title: "",
			width: "30px",
			headerClassName: "no-border",
			cell: (props) => <DotCell value={props.dataItem.status} />,
		},
		{ field: "registrationId", title: t("NumberShort"), width: "70px" },
		{
			field: "registrationDate",
			title: t("Date"),
			width: "110px",
			headerClassName: "no-border",
			cell: (props) => (
				<GrideDateFormatter
					date={props.dataItem.registrationDate}
					formatType={"dd.MM.yyyy"}
				/>
			),
		},
		{
			field: "registrationDate",
			title: t("Time"),
			width: "80px",

			cell: (props) => (
				<GrideDateFormatter
					date={props.dataItem.registrationDate}
					formatType={"HH:mm"}
				/>
			),
		},
		{
			field: "numberOfLines",
			title: t("Lines"),
			width: "80px",

			cell: (props) => <NumericalCell {...props} unit={""} />,
			headerClassName: CLASSNAME.TableNumeric,
			className: `${CLASSNAME.TableNumeric} no-border`,
			headerCell: (props) => <NumericalHeader {...props} />,
		},
		{ field: "vesselName", title: t("Vessel") },
		{ field: "fishingGear", title: t("FishingGear"), width: "160px" },
		{ field: "buyerName", title: t("Buyer"), width: "auto" },
		{
			field: "liveWeightKg",
			title: t("TotalWeight"),
			width: "120px",

			cell: (props) => <NumericalCell {...props} unit={"kg"} />,
			headerClassName: CLASSNAME.TableNumeric,
			className: `${CLASSNAME.TableNumeric} no-border`,
			headerCell: (props) => <NumericalHeader {...props} />,
		},
	];
	return gridItems;
};

export const NumericalHeader: FC<GridHeaderCellProps> = (props) => {
	return <span>{props.title}</span>;
};

export const NumericalCell: FC<GridCustomCellProps & { unit: string }> = ({
	field,
	dataItem,
	unit,
}) => {
	let value;
	if (field) {
		value = dataItem[field];
	}
	// let color = COLOR.TextLight;
	// if (dataItem.status === 0) {
	//   color = COLOR.Grey20;
	// }
	return (
		<td style={{ textAlign: "end", border: "none" }}>
			<span>{value}</span> {unit && <span>{unit}</span>}
		</td>
	);
};

export const lastColumItems = ({ edit }: { edit?: (data: any) => void }) => {
	return [
		{
			onclick: edit,
			conditionField: "status",
			iconName: ICON_NAMES.Edit,
			showConditionValue: 0,
		},
		{
			conditionField: "status",
			iconName: ICON_NAMES.GreenCheckbox,
			showConditionValue: 1,
		},
		{
			conditionField: "status",
			iconName: ICON_NAMES.Checkbox,
			showConditionValue: 0,
		},
	];
};
