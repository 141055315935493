import { GridCellProps, GridColumnProps } from "@progress/kendo-react-grid";
import { ICON_NAMES } from "utils/constant";
import { GridCommandCellMultiple } from "./GridCommandCell";

interface Props {
	onclick?: (props: GridCellProps) => void;
	iconName: string;
	showConditionValue?: any;
	conditionField?: string;
	alwaysShow?: boolean;
	style?: React.CSSProperties;
}

//Refactor this to handle different codition
export const lastColWithBtn = ({
	showDetails = true,
	items = [],
	openDetail,
	width = "50px",
	showDelete = false,
	deleteItem,
}: {
	showDetails: boolean;
	openDetail?: (props: GridCellProps) => void;
	items?: Props[];
	width?: string;
	showDelete?: boolean;
	deleteItem?: {
		btn: (props: GridCellProps) => void;
		showConditionValue: any;
		conditionField: string;
		style?: React.CSSProperties;
	};
}) => {
	const column: GridColumnProps = {
		field: "",
		title: "",
		width,
		// className: `${CLASSNAME.TableNumeric} no-border`,
		cell: (props) => {
			const arrow = props.expanded ? ICON_NAMES.ArrowUp : ICON_NAMES.ArrowDown;
			let cellItem = [];

			if (showDelete && deleteItem) {
				if (
					props.dataItem[deleteItem?.conditionField] ===
					deleteItem?.showConditionValue
				) {
					cellItem.push({
						onclick: deleteItem.btn,
						iconName: ICON_NAMES.Delete,
						style: deleteItem.style,
					});
				}
			}
			items.forEach((item) => {
				if (item.alwaysShow) {
					cellItem.push({
						onclick: item.onclick,
						iconName: item.iconName,
						style: item.style,
					});
				} else if (
					props.dataItem[item.conditionField as any] === item.showConditionValue
				) {
					cellItem.push({
						onclick: item.onclick,
						iconName: item.iconName,
						style: item.style,
					});
				}
			});
			if (showDetails) {
				cellItem.push({ onclick: openDetail, iconName: arrow });
			}
			return <GridCommandCellMultiple {...props} items={cellItem} />;
		},
	};
	return column;
};
