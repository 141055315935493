import { FC } from "react";
import { COLOR, ICON_NAMES } from "utils/constant";
import styles from "styles.module.css";
import CustomButton from "ui/Button";

interface Props {
	title: string;
	description: string;
	actions?: {
		text: string;
		btn: () => void;
		disabled?: boolean;
		bgColor?: string;
		textColor?: string;
	}[];
}
const AuthenticationSuccess: FC<Props> = ({
	title,
	description,
	actions = [],
}) => {
	return (
		<div style={{ width: "100%", margin: "auto", color: "white" }}>
			<div style={{ width: "500px", margin: "auto" }}>
				<div
					style={{ width: "100%", display: "flex", justifyContent: "center" }}
				>
					<img
						src={`/icon/${ICON_NAMES.CheckMark}.svg`}
						alt={"success"}
						style={{
							width: "180px",
							height: "180px",
							margin: "auto",
							background: COLOR.StatusGreen,
							borderRadius: "100%",
							borderColor: COLOR.StatusBlue,
							borderWidth: "2px",
							borderStyle: "solid",
						}}
					/>
				</div>
				<div>
					<div
						className={styles.BoxHeadingMedium}
						style={{
							color: "white",
							margin: "25px",
							width: "100%",
							display: "flex",
							justifyContent: "center",
						}}
					>
						<span style={{ textAlign: "center" }}>{title}</span>
					</div>
					<div style={{ display: "flex", justifyContent: "center" }}>
						<span>{description}</span>
					</div>
				</div>
				<div
					style={{
						display: "flex",
						justifyContent: "center",
						gap: "25px",
						marginTop: "40px",
					}}
				>
					{actions.map((action, index) => (
						<div key={index}>
							<CustomButton
								label={action.text}
								onClick={action.btn}
								disabled={action.disabled || false}
								bgColor={action.bgColor}
								color={action.textColor}
							/>
						</div>
					))}
				</div>
			</div>
		</div>
	);
};
export default AuthenticationSuccess;
