import { Buyer } from "interface/buyer";
import { AxiosApi } from "./index";
import { Vessel } from "interface/vessel";
import { DropDownItem, FishingGear, CatchArea, Package, Product, Specie, Country } from "interface/common";
import i18n from "../i18n";

let language = i18n.language;

export async function getBuyersApi({ text, id, getList = false }: { text?: string, id?: number, getList?: boolean }) {
    const apiUri = "api/common/buyers"

    try {
        const buyer = await AxiosApi.get<Buyer[]>(apiUri, {
            params: {
                id, text, getList
            }
        })
        return buyer.data
    } catch (error) {
        throw error
    }
}

export async function getBuyersFilterApi(text: string) {
    const apiUri = "api/common/buyers"

    try {
        const buyer = await AxiosApi.get<Buyer[]>(apiUri, {
            params: {
                text
            }
        })
        return buyer.data
    } catch (error) {
        throw error
    }
}


export async function getSpecies(text?: string, code?: number, getList: boolean = false) {
    const apiUrl = "api/common/species"

    try {
        const buyer = await AxiosApi.get<Specie[]>(apiUrl, {
            params: {
                code, text, getList, language
            }
        })
        return buyer.data
    } catch (error) {
        throw error
    }
}

export async function getFishingGearApi(text?: string, id?: number, getList: boolean = false) {
    const apiUrl = "api/common/gears"

    try {
        const buyer = await AxiosApi.get<FishingGear[]>(apiUrl, {
            params: {
                id, text, getList, language
            }
        })
        return buyer.data
    } catch (error) {
        throw error
    }
}

export async function getFDFishingGear(text?: string, code?: number, getList: boolean = false) {
    const apiUrl = "api/common/fdgears"

    try {
        const buyer = await AxiosApi.get<FishingGear[]>(apiUrl, {
            params: {
                code, text, getList
            }
        })
        return buyer.data
    } catch (error) {
        throw error
    }
}


export async function getTerminalsApi(text?: string, code?: string, getList: boolean = false) {
    const apiUri = "api/common/terminals"

    try {
        const buyer = await AxiosApi.get<DropDownItem[]>(apiUri, { params: { text, code, getList } })
        return buyer.data
    } catch (error) {
        throw error
    }
}
export async function getQuaySalePortApi({ id, vesselId }: { id?: number, vesselId?: string }) {
    const apiUri = "api/common/quay_ports"

    try {
        const buyer = await AxiosApi.get<DropDownItem[]>(apiUri, { params: { id, vesselId } })
        return buyer.data
    } catch (error) {
        throw error
    }
}

export async function getAllVessel({ text, resKto, source, date, rn, id, allowCallSign = false }: { text: string, date?: Date, allowCallSign?: boolean, source?: string, resKto?: number, id?: number, rn?: string }) {
    const apiUri = "Vessel/GetAll"

    try {
        const buyer = await AxiosApi.get<Vessel[]>(apiUri, {
            params: {
                text, resKto, source, date, id, allowCallSign, rn
            }
        })
        return buyer.data
    } catch (error) {
        throw error
    }
}

export async function getVesselFilter(text: string) {
    const apiUri = "Vessel/GetAll"

    try {
        const buyer = await AxiosApi.get<Vessel[]>(apiUri, {
            params: {
                text
            }
        })
        return buyer.data
    } catch (error) {
        throw error
    }
}

export async function getVesselByIdApi(vesselId: string) {
    const apiUri = "Vessel/Get"

    try {
        const buyer = await AxiosApi.get<Vessel>(apiUri, {
            params: {
                vesselId
            }
        })
        return buyer.data
    } catch (error) {
        throw error
    }
}

export async function getPackagesApi(text?: string) {
    const apiUri = "api/common/packages"
    try {
        const buyer = await AxiosApi.get<Package[]>(apiUri, { params: { text, language } })
        return buyer.data
    } catch (error) {
        throw error
    }
}

export async function getProductsApi(text: string, id?: number, reservation?: string, getList = true) {
    const apiUri = "api/common/products"
    try {
        const buyer = await AxiosApi.get<Product[]>(apiUri, { params: { text, id, reservation, language, getList } })
        return buyer.data
    } catch (error) {
        throw error
    }
}

export async function getCatchAreaApi(text?: string, id?: number, getList: boolean = false) {
    const apiUrl = "api/common/catchareas"

    try {
        const catchArea = await AxiosApi.get<CatchArea[]>(apiUrl, {
            params: {
                id, text, getList
            }
        })
        return catchArea.data
    } catch (error) {
        throw error
    }
}
// export async function getCountriesApi(code?: string) {
//     const apiUrl = "api/common/countries"

//     try {
//         const catchArea = await AxiosApi.get<DropDownItem[]>(apiUrl, {
//             params: {
//                 code
//             }
//         })
//         return catchArea.data
//     } catch (error) {
//         throw error
//     }
// }

export async function getQuotaTypeApi() {
    const apiUrl = "api/common/QuotaType"

    try {
        const catchArea = await AxiosApi.get<DropDownItem[]>(apiUrl, {
            
        })
        return catchArea.data
    } catch (error) {
        throw error
    }
}




//// ------- ReportSearch api   --------/////



export async function getFishingDepartmentIdNumbersApi(id: number) {
    const apiUrl = "api/ReportSearch/FishingDepartmentIdNumber"

    try {
        const catchArea = await AxiosApi.get<DropDownItem[]>(apiUrl, {
            params: {
                id
            }
        })
        return catchArea.data
    } catch (error) {
        throw error
    }
}

export async function getSearchUsageApi(text?: number) {
    const apiUrl = "api/ReportSearch/usage"

    try {
        const catchArea = await AxiosApi.get<DropDownItem[]>(apiUrl, {
            params: {
                text
            }
        })
        return catchArea.data
    } catch (error) {
        throw error
    }
}




export async function getFoodSafetyApprovalNumberApi(locationId?: number) {
    const apiUrl = "api/ReportSearch/FoodSafetyApprovalNumber"

    try {
        const catchArea = await AxiosApi.get<DropDownItem[]>(apiUrl, {
            params: {
                locationId
            }
        })
        return catchArea.data
    } catch (error) {
        throw error
    }
}

export async function getSearchBusinessNumberApi(id?: string) {
    const apiUrl = "api/ReportSearch/BusinessNumber"

    try {
        const catchArea = await AxiosApi.get<DropDownItem[]>(apiUrl, {
            params: {
                id
            }
        })
        return catchArea.data
    } catch (error) {
        throw error
    }
}

export async function getCountriesApi() {
    const apiUrl = "api/ReportSearch/countries"

    try {
        const catchArea = await AxiosApi.get<Country[]>(apiUrl, {
            params: {
                
            }
        })
        return catchArea.data
    } catch (error) {
        throw error
    }
}

export async function getCountryByCodeApi(code?: string) {
    const apiUrl = "api/ReportSearch/country"

    try {
        const catchArea = await AxiosApi.get<Country>(apiUrl, {
            params: {
                code
            }
        })
        return catchArea.data
    } catch (error) {
        throw error
    }
}

