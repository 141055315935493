import { t } from "i18next"

const condition = [
    {
        value: null,
        field: ''
    },
    {
        value: 1,
        field: 'Aktiv'
    },
    {
        Value: 0,
        field: 'Passiv'
    },
]



const YES_OR_NO_DROPDOWN = [
    {
        field: "Choose",
        value:  ""
    },
    {
        field: t("Yes"),
        value:  1
    },
    {
        field: t("No"),
        value:  0
    }
]

const LANDING_TYPE_DROPDOWN = [
    {
        field: "Choose",
        value:  null
    },
    {
        field: "Whole",
      // fieldNorsk: "Hele",
        value:  0
    },
    {
        field: "Part",
       // fieldNorsk: "Del",
        value:  1
    },

]


export const DROPDOWN_ITEMS = {
    condition, 
    status:  condition,
    yesOrNo: YES_OR_NO_DROPDOWN,
    landingType: LANDING_TYPE_DROPDOWN
}



/////// -----------Helpers -------//////

export  const getLandingTypeField = (value: number | string) => {
     const type = LANDING_TYPE_DROPDOWN.find(v=> v.value === value)
     if(type ) return type
     
     return LANDING_TYPE_DROPDOWN[0]

}