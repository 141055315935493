
import { create } from 'zustand'
import { UserRole , User, UserAccessOrganization } from '../interface/user'

interface UserStore {
    user: User | null,
    roles: UserRole[],
    setUser: (user: User | null) => void,
    setRoles: (access: UserRole []) => void,
    setStore: (access: UserRole [], user: User) => void,
    resetStore: () => void
}

export const useUserStore = create<UserStore>()((set) => ({
    user: null,
    roles: [],
    setUser: (user: User | null) => set(() => ({ user })),
    setRoles: (roles: UserRole[]) => set((state) => ({ roles })),
    setStore: (roles : UserRole[], user: User) => set(() => ({ roles , user })),
    resetStore: () => set(() => ({ UserRole : [], user: null })),
}))

interface UserOrganizationsStore {
    organizations: UserAccessOrganization[],
    defaultOrg: UserAccessOrganization| null,
    setOrganizations: (item: UserAccessOrganization[]) => void,
    setDefault: (item: UserAccessOrganization) => void,
}

export const useUserOrganizationsStore= create<UserOrganizationsStore>()((set) => ({
    defaultOrg: null,
    organizations:  [],
    setOrganizations: (organizations: UserAccessOrganization[]) => set(() => ({ organizations})),
    setDefault: (defaultOrg: UserAccessOrganization | null ) => set(() => ({  defaultOrg})),
}))