import { Dialog } from "@progress/kendo-react-dialogs";
import {
	GridCellProps,
	GridColumnProps,
	GridItemChangeEvent,
} from "@progress/kendo-react-grid";
import {
	deleteSalesNoteLineComment,
	getSalesNoteLineComments,
	SaveSalesNoteLineComment,
} from "api/salesNote";
import { t } from "i18next";
import React, { FC, useEffect, useRef, useState } from "react";
import CustomButton from "ui/Button";
import CustomGrid from "ui/grid/CustomGrid";
import { CustomGridCommandCell } from "ui/grid/GridCommandCell";
import { CLASSNAME, COLOR, ICON_NAMES } from "utils/constant";
import { Comment } from ".";
import style from "./style.module.css";
import Info from "ui/Info";

interface LineComment extends Comment {
	inEdit?: boolean;
}

const NoteModal: FC<{
	showModal: boolean;
	setShowModal: () => void;
	gridProps: GridCellProps;
}> = ({ showModal, setShowModal, gridProps }) => {
	const [lineComments, setLineComments] = useState<LineComment[]>([]);
	const [showDeleteLine, setShowDeleteLine] = useState(false);
	const deletingLineRef = useRef<LineComment>();

	const dataItem = gridProps.dataItem;

	useEffect(() => {
		getData();
	}, []);

	const handleOnChangeItem = (e: GridItemChangeEvent) => {
		console.log(e);
		const item = e.dataItem;
		addNewLine(item.id, e.value);
	};

	const getData = () => {
		getSalesNoteLineComments(dataItem.id)
			.then((response) => {
				setLineComments(
					response.data.map((line) => ({ ...line, inEdit: true }))
				);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const addNewLine = (id: number = 0, description: string = "") => {
		SaveSalesNoteLineComment({
			id,
			noteLineId: dataItem.id,
			description,
		})
			.then((response) => {
				getData();
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const handleDeleteItem = (data: GridCellProps) => {
		deletingLineRef.current = data.dataItem;
		setShowDeleteLine(true);
	};

	const deleteLine = () => {
		if (!deletingLineRef.current) return;
		deleteSalesNoteLineComment(deletingLineRef.current.id)
			.then((response) => {
				console.log("line deleted");
				setShowDeleteLine(!showDeleteLine);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const gridItems: GridColumnProps[] = [
		{
			field: "lineNumber",
			title: "Lnr",
			width: "40px",
			className: ` no-border`,
			editable: false,
		},
		{
			field: "description",
			title: "Notat",
			editable: true,
			className: ` no-border`,
			editor: "text",
		},
		{
			field: "",
			title: "",
			cell: (props) => (
				<CustomGridCommandCell
					{...props}
					style={{ display: "flex", justifyContent: "flex-end" }}
					onclick={handleDeleteItem}
					iconName={ICON_NAMES.Delete}
				/>
			),
			width: "60px",
		},
	];

	return (
		<>
			{showModal && (
				<Dialog
					title={`Note line  comments `}
					onClose={() => setShowModal()}
					className={style.ModalContainer}
					height={"70%"}
					contentStyle={{
						height: "70%",
					}}
				>
					<div>
						<CustomGrid
							gridColumns={gridItems}
							data={lineComments}
							editField="inEdit"
							className={CLASSNAME.TableNumeric + " " + CLASSNAME.HideOverFlow}
							onItemChange={handleOnChangeItem}
						/>
						<div
							style={{
								marginTop: "20px",
								display: "flex",
								justifyContent: "space-between",
							}}
						>
							<CustomButton
								label={t("NewLine") + " +"}
								onClick={addNewLine}
								bgColor={COLOR.Green}
								color="white"
							/>
						</div>
						<Info
							close={() => setShowDeleteLine(!showDeleteLine)}
							title={t("DeleteLine")}
							show={showDeleteLine}
							description={t("DeleteLineConfirmation")}
							actions={[
								{
									text: t("Cancel"),
									btn: () => setShowDeleteLine(!showDeleteLine),
								},
								{
									text: t("Yes"),
									btn: deleteLine,
									bgColor: "red",
									textColor: "white",
								},
							]}
						/>
					</div>
				</Dialog>
			)}
		</>
	);
};

export default NoteModal;
