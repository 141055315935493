import {
	GridColumnProps,
	GridCustomCellProps,
	GridHeaderCellProps,
} from "@progress/kendo-react-grid";
import { CLASSNAME } from "utils/constant";
import { GrideDateFormatter } from "ui/Date";
import { FC } from "react";
//import styles from "styles.module.css";
import i18n from "../../../i18n";
import { CheckMarkCell } from "ui/grid/CheckMarkCell";
import DotCell from "ui/grid/DotCell";
import { compareAsc } from "date-fns";

let t = i18n.t;

export const InvoiceGridColumnItem = () => {
	const gridItems: GridColumnProps[] = [
		{
			//This wont show up
			field: "registrationDate",
			title: "Dato",
		},
		// {
		// 	field: "",
		// 	title: "",
		// 	width: "30px",
		// 	headerClassName: "no-border",
		// 	cell: (props) => <DotCell value={props.dataItem.status} />,
		// },
		{
			field: "invoiceDate",
			title: "Fakturert",
			headerClassName: "no-border",
			cell: (props) => (
				<GrideDateFormatter
					date={props.dataItem.invoiceDate}
					formatType={"dd.MM.yyyy"}
				/>
			),
			// width: "110px",
		},
		{
			field: "dueDate",
			title: "Forfall",
			headerClassName: "no-border",
			cell: (props) => (
				<GrideDateFormatter
					style={{
						color: isDueDatePassed(
							props.dataItem.dueDate,
							props.dataItem.remainingValue
						)
							? "red"
							: "",
					}}
					date={props.dataItem.dueDate}
					formatType={"dd.MM.yyyy"}
				/>
			),
		},
		{
			field: "invoiceNumber",
			title: "Faktura Nr",
			cell: (props) => {
				return (
					<td style={props.style} className={props.className + " no-border"}>
						<span>{`${props.dataItem.invoiceType}  ${props.dataItem["invoiceNumber"]}`}</span>
					</td>
				);
			},
			// width: "100px",
			headerClassName: "no-border",
		},
		{
			field: "customerName",
			title: "Beskrivelse",
			//width: "150px",
			headerClassName: "no-border",
		},
		{
			field: "ehfAccessResponse",
			title: "EHF",
			cell: (props) => (
				<CheckMarkCell
					{...props}
					checked={props.dataItem["invoiceSentDate"] !== null ? true : false}
				/>
			),
		},

		{
			field: "netValue",
			title: "Beløp Eks. MVA",
			width: "120px",
			cell: (props) => <NumericalCell {...props} unit={"kr"} />,
			headerClassName: CLASSNAME.TableNumeric,
			className: `${CLASSNAME.TableNumeric} no-border`,
			headerCell: (props) => <NumericalHeader {...props} />,
		},
		{
			field: "grossValue",
			title: "Beløp ink mva",
			width: "120px",
			cell: (props) => <NumericalCell {...props} unit={"kr"} />,
			headerClassName: CLASSNAME.TableNumeric,
			className: `${CLASSNAME.TableNumeric} no-border`,
			headerCell: (props) => <NumericalHeader {...props} />,
		},
		{
			field: "paidValue",
			title: "Betalt",
			width: "120px",
			cell: (props) => <NumericalCell {...props} unit={"kr"} />,
			headerClassName: CLASSNAME.TableNumeric,
			className: `${CLASSNAME.TableNumeric} no-border`,
			headerCell: (props) => <NumericalHeader {...props} />,
		},
		{
			field: "remainingValue",
			title: "Restbeløp",
			width: "120px",
			cell: (props) => (
				<NumericalCell
					{...props}
					style={{
						color: isDueDatePassed(
							props.dataItem.dueDate,
							props.dataItem.remainingValue
						)
							? "red"
							: "",
					}}
					unit={"kr"}
				/>
			),
			headerClassName: CLASSNAME.TableNumeric,
			className: `${CLASSNAME.TableNumeric} no-border`,
			headerCell: (props) => <NumericalHeader {...props} />,
		},
	];
	return gridItems;
};

export const NumericalHeader: FC<
	GridHeaderCellProps & { style?: React.CSSProperties }
> = (props) => {
	return <span style={{ ...props.style }}>{props.title}</span>;
};

export const NumericalCell: FC<GridCustomCellProps & { unit?: string }> = ({
	field,
	dataItem,
	unit,
	style,
}) => {
	let value;
	if (field) {
		value = dataItem[field];
	}

	return (
		<td style={{ textAlign: "end", border: "none", ...style }}>
			<span>{value}</span> {unit && <span>{unit}</span>}
		</td>
	);
};

const isDueDatePassed = (dueDate: Date, remainingValue: number) => {
	const result = compareAsc(new Date(dueDate), new Date());
	return result === -1 && remainingValue !== 0;
};
